import { Component, forwardRef, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { DefaultValueAccessor } from '../forms/value-accessors/default-value-accessor';
import { CheckboxGroupComponent } from './checkbox-group';

@Component({
  selector: 'hn-checkbox',
  templateUrl: './checkbox.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true
  }]
})

export class CheckboxComponent extends DefaultValueAccessor<any> implements AfterViewInit, OnDestroy, ControlValueAccessor {
  @Input('color')
  public color: any = 'primary';

  @Input('isreadonly')
  public isReadOnly: boolean;

  @Input('set-value')
  public setValue: any = true;

  @Input('unset-value')
  public unsetValue: any = false;

  @Input('group')
  private _group: CheckboxGroupComponent;

  public ngAfterViewInit(): void {
    if (this._group) {
      this._group.addCheckbox(this);
    }
  }

  public ngOnDestroy(): void {
    if (this._group) {
      this._group.removeCheckbox(this);
    }
  }

  public toNgModel(value: any) {
    if (value) {
      return this.setValue;
    } else {
      return this.unsetValue;
    }
  }

  public fromNgModel(value: any) {
    return value === this.setValue;
  }

  public resetValue() {
    this.value = false;
  }

  public markAsChecked(): void {
    this.value = true;
  }

  public markAsUnChecked(): void {
    this.value = false;
  }

  public onModelChanged() {
    if (this._group) {
      this._group.onModelChanged();
    }
  }
}
