<hn-dropdown-menu #dropDownMenu>
    <ng-container header>
        <div class="category-search-container">
            <hn-icon pack="shared" icon="search" class="search" color="secondary" size="1"></hn-icon>
            <span class="selected-category no-select" #selectedCategoryNamePlaceholder>
                {{selectedCategoryName}}
            </span>
          <input
              type="text"
              class="form-control"
              (click)="onInputClick($event)"
              [placeholder]="placeholder"
              [(ngModel)]="searchValue.search"
              (ngModelChange)="onSearchValueChange($event)"
              [style.padding-left.rem]="inputPadding"
              (keydown.enter)=close()>
          <hn-icon
            class="clear pointer"
            *ngIf="searchValue.search"
            (click)="onClearSearch($event)"
            pack="shared"
            icon="cancel"
            color="secondary"
            size="1"></hn-icon>
        </div>
    </ng-container>
    <ng-container menu>
        <div *ngFor="let category of categories" class="pointer" (click)="categorySelected(category)">
            <ng-container *ngTemplateOutlet="categoryTemplate; context: { $implicit: category }">
            </ng-container>
        </div>
    </ng-container>
</hn-dropdown-menu>
