import { Component, HostBinding, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  // tslint:disable:component-selector
  selector: '.form-control',
  template: `
    <ng-content></ng-content>
  `
})
export class FormControlComponent {

  @Input() private validationDisabled = false;

  @HostBinding('class.is-invalid')
  get hasErrors() {
    return this.isInvalid;
  }

  get isInvalid() {
    return this.ngControl &&
    this.ngControl.invalid &&
    this.ngControl.touched &&
    !this.validationDisabled;
  }


  constructor(@Self() @Optional() private ngControl: NgControl) { }

}
