import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateRangeComponent } from './date-range/date-range.component';
import { IconModule } from '../icon';
import { DateIconPack } from './date.icons';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DateRangeHeaderComponent } from './date-range/date-header/date-header.component';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';

const ENTRY_COMPONENTS = [
    DateRangeHeaderComponent
];

const IMPORTS = [
    IconModule.register(DateIconPack),
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    FormsModule,
    CommonModule
];

const EXPORTS = [
    DateRangeComponent
];

const DECLARATIONS = [
    ENTRY_COMPONENTS,
    EXPORTS
];

const PROVIDERS = [
    { provide: MAT_DATE_LOCALE, useValue: 'en-AU' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
];

@NgModule({
    imports: IMPORTS,
    declarations: [DECLARATIONS],
    entryComponents: [ENTRY_COMPONENTS],
    exports: [EXPORTS, IMPORTS],
    providers: PROVIDERS
})

export class DateModule { }
