import { Component, Input, ContentChild, TemplateRef, forwardRef, ViewChild, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DropdownMenuComponent } from '../dropdown-menu/dropdown-menu.component';
import { DefaultValueAccessor } from '../forms/value-accessors/default-value-accessor';
import { ICategorySearchValue } from './category-search-value.interface';

@Component({
  selector: 'hn-category-search',
  styleUrls: ['./category-search.component.scss'],
  templateUrl: './category-search.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CategorySearchComponent),
    multi: true
  }]
})

export class CategorySearchComponent extends DefaultValueAccessor<ICategorySearchValue>
implements ControlValueAccessor, OnDestroy, OnInit {
  @Input('placeholder')
  public placeholder = '';

  @Input('categories')
  public categories: any[] = [];

  @Input('category-name-property')
  public categoryNameProperty: string;

  @ViewChild('selectedCategoryNamePlaceholder')
  public selectedCategoryNamePlaceholder: ElementRef;

  @ViewChild('dropDownMenu')
  private _dropDownMenu: DropdownMenuComponent;

  @ContentChild(TemplateRef)
  public categoryTemplate: TemplateRef<any>;

  public get selectedCategoryName() {
    if (!this.searchValue.category) {
      return '';
    }

    return this.categoryNameProperty
      ? `${this.searchValue.category[this.categoryNameProperty]}:`
      : this.searchValue.category;
  }

  public inputPadding = 2.5;

  public searchValue: ICategorySearchValue = {
    search: '',
    category: null
  };

  private _unsubscribeAll = new Subject();
  private _valueUpdate = new Subject<any>();

  public ngOnInit() {this._valueUpdate
    .pipe(
      takeUntil(this._unsubscribeAll),
      distinctUntilChanged())
    .subscribe(() => {
        this._dropDownMenu.open();
      });
  }

  public ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  public onSearchValueChange(search: string) {
    this.value = {
      search: search,
      category: search ? this.searchValue.category : null
    };
    this.searchValue = this.value;

    if (search) {
      this._valueUpdate.next(search);
    }

    if (!this.value.category) {
      this.updateCategoryPadding();
    }
  }

  public onClearSearch(event) {
    event.stopPropagation();

    this.onSearchValueChange('');
  }

  public onInputClick(event) {
    event.stopPropagation();
  }

  public close() {
    this._dropDownMenu.close();
  }

  public categorySelected(category: any) {
    this.value = {
      search: this.searchValue.search,
      category: category
    };

    this.searchValue = this.value;
    this.updateCategoryPadding();
  }

  private updateCategoryPadding() {
    let padding = 2.5;
    this.selectedCategoryNamePlaceholder.nativeElement.innerText = '';

    if (this.searchValue.category) {
      this.selectedCategoryNamePlaceholder.nativeElement.innerText = this.selectedCategoryName;
      const pxWidth = this.selectedCategoryNamePlaceholder.nativeElement.clientWidth;
      const categoryPlaceholderRemWidth = pxWidth / 16;

      padding += categoryPlaceholderRemWidth + 0.5;
    }

    this.inputPadding = padding;
  }
}
