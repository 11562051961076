import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseXeroCallbackComponent } from '../base-xero-callback/base-xero-callback.component';
import {
    ServiceSettingsDatasource,
    ContactFieldsDatasource,
    ServicesDatasource,
} from 'src/app/shared';
import { AesEncryption } from 'src/app/shared/services/encryption';

@Component({
    selector: 'hn-xpm-callback',
    templateUrl: '../../callback.component.html',
    providers: [
        ServicesDatasource,
        ServiceSettingsDatasource,
        ContactFieldsDatasource
    ]
})

export class XpmCallbackComponent extends BaseXeroCallbackComponent implements OnInit {
    constructor(public activatedRoute: ActivatedRoute,
        public router: Router,
        public serviceDatasource: ServicesDatasource,
        public serviceSettingsDatasource: ServiceSettingsDatasource,
        public contactFieldsDatasource: ContactFieldsDatasource,
        public encryption: AesEncryption,
        public modalService: NgbModal) {
        super('xpm', 'PRACTICEMANAGER',
            activatedRoute,
            router,
            serviceDatasource,
            serviceSettingsDatasource,
            contactFieldsDatasource,
            encryption,
            modalService);
    }

    public ngOnInit(): void {
        this.registerService();
    }
}
