<div>
  <div class="bg-white d-flex justify-content-around py-3 px-2 border-bottom">
    <div class="date-dropdown-header-date-value">
      <input type="text" class="form-control form-control-outline text-center h-100" [(ngModel)]="startDate"
        (ngModelChange)="startDateChange()">
    </div>
    <hn-icon class="my-auto" pack="shared" icon="arrow" size="1" color="secondary-600"></hn-icon>
    <div class="date-dropdown-header-date-value">
      <input type="text" class="form-control form-control-outline text-center h-100" [(ngModel)]="endDate"
        (ngModelChange)="endDateChange()">
    </div>
  </div>
  <div class="d-flex pt-2 pb-3 px-4 justify-content-between">
    <button type="button" mat-icon-button [disableRipple]="true" (click)="previousMonthClicked()">
      <hn-icon icon="keyboard_arrow_left" color="secondary-500"></hn-icon>
    </button>
    <b class="pt-2">{{periodLabel}}</b>
    <button type="button" mat-icon-button [disableRipple]="true" (click)="nextMonthClicked()">
      <hn-icon icon="keyboard_arrow_right" color="secondary-500"></hn-icon>
    </button>
  </div>
</div>