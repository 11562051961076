import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WorkflowSettingsDto } from '.';

@Injectable()
export class WorkflowOptionsDataSource {
    private _dataUrl: string = 'options/workflow';

    constructor(private _http: HttpClient) { }

    public getSettings() {
        return this._http.get<WorkflowSettingsDto>(`${this._dataUrl}/settings`);
    }

    public updateSettings(data) {
        return this._http.put(`${this._dataUrl}/settings`, data);
    }
}
