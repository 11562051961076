<div class="row pointer" *ngIf="value" (click)="picker.open()">
  <div class="col-2" #pickerOpen>
    <hn-icon pack="date" icon="calendar" size="1.2" [color]="color">
    </hn-icon>
  </div>
  <div class="col text-truncate no-select">
    {{value.start ? (value.start | date:'MMM dd - ') : startPlaceholder}}
    {{value.end  ? (value.end | date:'MMM dd') : endPlaceholder}}
    <mat-date-range-input class="hide" [rangePicker]="picker">
      <input matStartDate class="hide" [(ngModel)]="value.start" (ngModelChange)="onModelChange()">
      <input matEndDate class="hide" [(ngModel)]="value.end" (ngModelChange)="onModelChange()">
    </mat-date-range-input>
    <mat-date-range-picker [touchUi]="touchUi" [calendarHeaderComponent]="datePickerHeader" #picker>
    </mat-date-range-picker>
  </div>
</div>