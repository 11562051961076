<hn-dropdown-menu [full-size]="false" [autoclose]="false" menu-class="table-header-filter-menu" #menu>
  <ng-container header>
      <span class="no-select pointer" [class.color-primary]="applied">
          <span [class.bold]="applied">
              <ng-content>
              </ng-content>
          </span>

          <ng-container *ngIf="applied; else arrow">
              <hn-icon class="pointer ml-1" (click)="onClearFilters($event)" pack="shared" icon="cancel"
                  color="primary" size="0.8"></hn-icon>
          </ng-container>
          <ng-template #arrow>
              <hn-icon *ngIf="!hideArrow" class="pointer ml-1" pack="shared" icon="down" size="0.6"></hn-icon>
          </ng-template>
      </span>
  </ng-container>
  <ng-container menu>
      <ng-container [ngSwitch]="type">
          <ng-container *ngSwitchCase="'text'">
              <ng-container *ngTemplateOutlet="textFilter"></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'select'">
              <ng-container *ngTemplateOutlet="selectFilter"></ng-container>
          </ng-container>
      </ng-container>
  </ng-container>
</hn-dropdown-menu>

<ng-template #textFilter>
  <div class="px-3 py-1 text-left pointer no-select" (click)="onSortAsc()"
      [class.color-primary]="sort === 'Ascending'">
      <hn-icon pack="shared" icon="sort-ascending" size="1.4"
          [color]="sort === 'Ascending' ? 'primary': 'secondary-800'">
      </hn-icon>
      Sort A - Z
  </div>
  <div class="px-3 py-1 text-left pointer mt-2 no-select" (click)="onSortDesc()"
      [class.color-primary]="sort === 'Descending'">
      <hn-icon pack="shared" icon="sort-descending" size="1.4"
          [color]="sort === 'Descending' ? 'primary': 'secondary-800'">
      </hn-icon>
      Sort Z - A
  </div>
  <div class="mt-2 px-3 py-1" *ngIf="!hideFilter">
      <span class="no-select">Contains</span>
      <div class="d-flex py-2 search-container">
          <input type="text" class="search bg-secondary-100 form-control h-100" [(ngModel)]="value"
              (ngModelChange)="onSearchChange($event)">
          <hn-icon class="clear pointer" *ngIf="value" (click)="onClearSearch()" pack="shared" icon="cancel"
              color="secondary" size="1"></hn-icon>
      </div>
  </div>
</ng-template>

<ng-template #selectFilter>
  <div class="no-select pl-3">
      <hn-checkbox [(ngModel)]="allSelected" (click)="onAllOptionsSelected()">
          All types
      </hn-checkbox>
  </div>

  <div class="py-1 checkbox-container overflow-auto">
    <hn-checkbox-group
      [(ngModel)]="value"
      (ngModelChange)="onCheckboxModelChange()"
      #optionsCheckboxGroup>
          <div *ngFor="let option of filterProperties" class="no-select pl-3">
            <hn-checkbox
              [group]="optionsCheckboxGroup"
              [set-value]="option"
              [unset-value]="undefined">
                  {{option}}
              </hn-checkbox>
          </div>
      </hn-checkbox-group>
  </div>

  <div class="d-flex justify-content-around px-3">
      <button mat-flat-button color="primary" (click)="onApplyFilter()">Save</button>
      <button mat-flat-button (click)="onCancel()">Cancel</button>
  </div>
</ng-template>
