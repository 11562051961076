import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IGbuRevenue } from './models/gbu-revenue.interface';

@Injectable()
export class GbuDatasource {
    private _dataUrl: string = 'reporting-gbu';

    constructor(private _http: HttpClient) { }

    public getGbuRevenue(): Observable<IGbuRevenue[]> {
        return this._http.get<IGbuRevenue[]>(`${this._dataUrl}/gbu-revenue`);
    }
}
