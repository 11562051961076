export type AppliedDevices = 'all' | 'mobile' | 'pc';

export abstract class BaseChartExtension {
    public appliedDevices: AppliedDevices;
    public applyChanges(chartElement: any): void {}

    protected getChartSvg(chartElement: any): any {
        return chartElement.getElementsByTagName('svg')[0];
    }
}
