import { NgModule } from '@angular/core';
import { Compiler } from './compiler/compiler.service';
import { ScreenResolutionService } from './screen-resolution/screen-resolution.service';
import { FileService } from './file/file.service';
import { LocalStorage } from './storage/local-storage.service';

@NgModule({
    providers: [
        ScreenResolutionService,
        Compiler,
        FileService,
        LocalStorage
    ]
})

export class CoreServicesModule { }
