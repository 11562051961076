// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { InjectionToken } from '@angular/core';

export interface Configuration {
  apiEndpoint: string;
  previewDocumentEndpoint: string;
  clientSettings: {
    issuer: string,
    clientId: string,
    redirectUri: string,
    postLogoutRedirectUri: string,
    responseType: string,
    scope: string,
    useWopi: boolean,
    showDebugInformation: boolean,
    limitMenuOptions: boolean,
  };
  productUrls: {
    howNow: string,
    howNowPortal: string,
    howNowWorkflow: string,
    reportance: string,
    smartSuite: string,
    workpapers: string,
    templates: string,
    goodBadUgly: string,
  };
}

export const CONFIG = new InjectionToken<Configuration>('CONFIG');

export const environment = {
  production: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
