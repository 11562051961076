import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'hn-icon',
    styleUrls: ['./icon.component.scss'],
    templateUrl: './icon.component.html'
})

export class IconComponent {
    @Input('pack')
    public pack: string;

    @Input('icon')
    public set icon(value) {
        this._iconName = value;

        const pack = this.pack ? `${this.pack}:` : '';

        this.defaultIcon = null;
        this.iconWithPack = null;

        if (pack) {
            this.iconWithPack = `${pack}${value}`;
            return;
        }

        this.defaultIcon = this._iconName;
    }

    @Input('color')
    public color: string;

    @Input('rotate')
    public rotate: string;

    @Input('size')
    public size: string;

    @HostBinding('style.line-height')
    public get lineHeight() {
        return this.size ? `${this.size}rem` : '';
    }

    public get iconFontSize() {
        return this.pack ? '' : `${this.size}rem`;
    }

    private _iconName: string;
    public defaultIcon: string;
    public iconWithPack: string;

    public get classes(): string {
        const colorClass = this.color ? `color-${this.color}` : '';
        const rotateClass = this.rotate ? `rotate-${this.rotate}` : '';

        return `${colorClass} ${rotateClass}`;
    }
}
