<div class="modal-header">
    <h3>Select Tenant</h3>
</div>
<div class="modal-body">
    <div *ngIf="modalEnabled">
        <div>
            Select the tenant you'd like to sync:<br />
        </div>
        <select *ngIf="modalEnabled" [value]="selectedTenant">
            <option *ngFor="let t of tenants" [value]="t">{{t.tenantName}}</option>
        </select>
    </div>
</div>
<div class="modal-footer">
    <button *ngIf="modalEnabled" class="btn btn-primary" (click)="selectTenant()">Select</button>
</div>