import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SlideOutView } from './slide-out-view.service';
import { SlideOutViewContainerComponent } from './slide-out-view-container';
import { SlideOutViewComponent } from './slide-out-view';

const ENTRY_COMPONENTS = [
    SlideOutViewContainerComponent
];

const IMPORTS = [
    CommonModule
];

const EXPORTS = [
    SlideOutViewComponent
];

const DECLARATIONS = [
    ENTRY_COMPONENTS,
    EXPORTS
];

const PROVIDERS = [
    SlideOutView
];

@NgModule({
    imports: IMPORTS,
    declarations: [DECLARATIONS],
    entryComponents: [ENTRY_COMPONENTS],
    exports: [EXPORTS, IMPORTS],
    providers: PROVIDERS
})

export class SlideOutViewModule { }
