<div class="row m-0 header">
    <div class="col-3 pl-0 d-flex d-none d-lg-block">
        <button type="button" class="d-block d-xl-none px-4" mat-button (click)="sidebarOpened.emit()">
            <hn-icon pack="shared" icon="burger" size="1" color="secondary">
            </hn-icon>
        </button>
    </div>

    <div class="col-9 pr-0 d-flex justify-content-end">
        <hn-products-navigation>
        </hn-products-navigation>

        <hn-dropdown-menu>
            <ng-container header>
                <button mat-button class="h-100" [disableRipple]="true">
                    {{name}}
                    <hn-icon pack="shared" icon="chevron" size="1" color="secondary">
                    </hn-icon>
                </button>
            </ng-container>

            <ng-container menu>
              <button mat-menu-item onclick="location.href = 'https://support.hownowhq.com/en/support/solutions/folders/5000307621'">
                Help & Support
              </button>
              <button mat-menu-item (click)="logoff()">Logout</button>
            </ng-container>
        </hn-dropdown-menu>
    </div>
</div>
