import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'hn-slide-out-view',
  templateUrl: './slide-out-view.component.html',
  styleUrls: ['./slide-out-view.component.scss'],
  animations: [
    trigger('openClosePanel', [
      state('opened', style({
        right: '0%'
      })),
      state('closed', style({
        right: '-100%'
      })),
      transition('opened <=> closed', [
        animate('0.4s')
      ])
    ]),
    trigger('openCloseWrapper', [
      state('opened', style({
        'opacity': '0.7',
        'display': 'block'
      })),
      state('closed', style({
        'opacity': '0',
        'display': 'none'
      })),
      transition('opened <=> closed', [
        animate('0.1s')
      ])
    ])
  ],
})

export class SlideOutViewComponent implements OnInit {
  @Input('hide-wrapper')
  public hideWrapper: boolean = false;

  @Input('width')
  public width: string;

  @ViewChild(SlideOutViewComponent)
  public _concreteSlideOutType: SlideOutViewComponent;

  public get style() {
    return this.sanitizer.bypassSecurityTrustStyle(
      `--right: -${this.width}; --width: ${this.width}`
    );
  }

  public onClose: EventEmitter<any> = new EventEmitter();
  public isOpened: boolean = false;

  public ngOnInit() {
    if (!this.width) {
      this.width = '30%';
    }
  }

  public constructor(private sanitizer: DomSanitizer) { }

  public close(result?: any) {
    this.onClose.emit(result);
  }
}
