<div class="modal-header">
    <h3>{{title}}</h3>
</div>
<div class="modal-body">
    <div >
        {{body}}
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-primary" (click)="ok()">Ok</button>
    <button class="btn btn-primary" (click)="cancel()">Cancel</button>
</div>