import { ContentChild, Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[hnTableViewCellActions]'
})
export class TableViewCellActionsDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[hnTableViewCellHeader]'
})
export class TableViewCellHeaderDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[hnTableViewCellContent]'
})
export class TableViewCellContentDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: 'hn-table-view-cell'
})
export class TableViewCellDirective {
  @Input('name')
  public name: string;

  @Input('cell-class')
  public klass: string;

  @ContentChild(TableViewCellActionsDirective) public actions: TableViewCellActionsDirective;

  @ContentChild(TableViewCellHeaderDirective) public header: TableViewCellHeaderDirective;

  @ContentChild(TableViewCellContentDirective) public content: TableViewCellContentDirective;
}
