import { BaseChartExtension, AppliedDevices } from '../base.ext';

export class BarAnnotationLabelPointerChartExtension extends BaseChartExtension {

    constructor(appliedDevices: AppliedDevices) {
        super();
        this.appliedDevices = appliedDevices;
    }

    public applyChanges(chartElement: any): void {
        const svg = super.getChartSvg(chartElement);
        if (!svg) {
            return;
        }

        const g = svg.getElementsByTagName('g')[0];
        const labelsG = g.children[g.children.length - 2];

        const texts = labelsG.getElementsByTagName('text');
        for (let i = 0; i < texts.length; i++) {
            texts[i].style.cursor = 'pointer';
        }

        const barRectangles = g.children[1].getElementsByTagName('rect');
        for (let i = 0; i < barRectangles.length; i++) {
            barRectangles[i].style.cursor = 'pointer';
        }

        const annotationsG = g.children[g.children.length - 1];
        const annotations = annotationsG.getElementsByTagName('text');
        for (let i = 0; i < annotations.length; i++) {
            annotations[i].style.cursor = 'pointer';
        }
    }
}
