import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ScreenResolutionService } from '../../../services/screen-resolution/screen-resolution.service';
import { DefaultValueAccessor } from '../../forms/value-accessors/default-value-accessor';
import { DateRangeHeaderComponent } from './date-header';
import { IDateRangeValue } from './date-range-value.interface';

@Component({
    selector: 'hn-date-range',
    templateUrl: './date-range.component.html',
    styleUrls: ['./date-range.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DateRangeComponent),
        multi: true
    }]
})

export class DateRangeComponent extends DefaultValueAccessor<IDateRangeValue> implements ControlValueAccessor {
    @Input('start-placeholder')
    public startPlaceholder = 'Start date';

    @Input('end-placeholder')
    public endPlaceholder = 'End date';

    @Input('color')
    public color = 'secondary-600';

    public get touchUi() {
        return this._screenResolutionService.isMobile();
    }

    @ViewChild('pickerOpen')
    private _pickerOpenElement: ElementRef;

    public datePickerHeader = DateRangeHeaderComponent;

    constructor(private _screenResolutionService: ScreenResolutionService) {
        super();
    }

    public onModelChange() {
        this.value = {
            start: this.value.start,
            end: this.value.end
        };
    }

    public open() {
        setTimeout(() => {
            this._pickerOpenElement.nativeElement.click();
        }, 0);
    }
}
