import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import {
    TableViewComponent,
    TableViewTableLayoutDirective,
    TableViewListLayoutDirective,
    TableViewTilesLayoutDirective
} from './table-view.component';
import {
    TableViewCellContentDirective,
    TableViewCellDirective,
    TableViewCellHeaderDirective,
    TableViewCellActionsDirective
} from './table-view-cell/table-view-cell.directive';

import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';

const IMPORTS = [
    CommonModule,
    FormsModule,
    MaterialModule,
    VirtualScrollerModule
];

const EXPORTS = [
    TableViewComponent,
    TableViewCellDirective,
    TableViewCellContentDirective,
    TableViewCellHeaderDirective,
    TableViewCellActionsDirective,
    TableViewTableLayoutDirective,
    TableViewListLayoutDirective,
    TableViewTilesLayoutDirective,
];

const DECLARATIONS = [
    EXPORTS
];

@NgModule({
    imports: IMPORTS,
    declarations: [DECLARATIONS],
    exports: [EXPORTS, IMPORTS]
})

export class TableViewModule { }
