import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SystemSettingsDto, UpdateSettingsDto } from '.';

@Injectable()
export class SystemSettingsOptionsDataSource {
  private _dataUrl: string = 'options/system-settings';

  constructor(private _http: HttpClient) { }

  public getSystemSettings() {
    return this._http.get<SystemSettingsDto>(`${this._dataUrl}/system-details`);
  }

  public setSystemSettings(data) {
    return this._http.put(`${this._dataUrl}/system-details`, data);
  }

  public getUpdateSettings() {
    return this._http.get<UpdateSettingsDto>(`${this._dataUrl}/update-settings`);
  }

  public setUpdateSettings(data) {
    return this._http.put(`${this._dataUrl}/update-settings`, data);
  }
}
