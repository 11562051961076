import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IService } from './models/service.interface';
import { IServiceDetails } from './models/service-details.interface';

@Injectable()
export class ServicesDatasource {
    private _dataUrl: string = 'services';

    constructor(private _http: HttpClient) { }

    public getLoginUri(serviceTag: string): Observable<string> {
        return this._http.get<any>(`${this._dataUrl}/${serviceTag}/login`).pipe(map(response => response.uri));
    }

    public getToken(serviceName: string, code: string): Observable<any> {
        return this._http.get<any>(`${this._dataUrl}/${serviceName}/token?code=${code}`);
    }

    public getConfigured(): Observable<IService[]> {
        return this._http.get<IService[]>(`${this._dataUrl}/configured`);
    }

    public getById(serviceId: number): Observable<IService> {
        return this._http.get<any>(`${this._dataUrl}/${serviceId}`);
    }

    public create(service: IService): Observable<any> {
        return this._http.post<any>(`${this._dataUrl}`, service);
    }

    public delete(serviceId: number): Observable<any> {
        return this._http.delete<any>(`${this._dataUrl}/${serviceId}`);
    }

    public put(serviceId: number, serviceDetails: IServiceDetails): Observable<any> {
        return this._http.put<any>(`${this._dataUrl}/${serviceId}`, serviceDetails);
    }
}
