import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DropdownMenuComponent } from '../dropdown-menu/dropdown-menu.component';
import { ITableFilterModel, SortBy } from './table-filter-model.interface';

@Component({
  selector: 'hn-table-header-filter',
  templateUrl: './table-header-filter.component.html',
  styleUrls: ['./table-header-filter.component.scss']
})

export class TableHeaderFilterComponent implements OnInit, OnDestroy {
  @Input('applied')
  public applied = false;

  @Input('hide-filter')
  public hideFilter = false;

  @Input('hide-arrow')
  public hideArrow = false;

  @Input('type')
  public type = 'text';

  @Input('filter-properties')
  public filterProperties: any;

  @Output('update')
  private _onFilterUpdate = new EventEmitter<ITableFilterModel | null>();

  @ViewChild('menu')
  private _menu: DropdownMenuComponent;

  public sort: SortBy;
  public value: any;
  public allSelected = false;

  private get _filterModel(): ITableFilterModel | null {
    const filters: ITableFilterModel = {};
    if (this.value) {
      filters.value = Array.isArray(this.value) ? this.value.join(',') : this.value;
    }

    if (this.sort) {
      filters.sort = this.sort;
    }

    if (Object.values(filters).length > 0) {
      return filters;
    }

    return null;
  }

  public filterUpdate = new Subject<any>();
  private _unsubscribeAll = new Subject();

  public ngOnInit() {
    this.filterUpdate.pipe(takeUntil(this._unsubscribeAll),
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(() => {
        this._onFilterUpdate.emit(this._filterModel);
      });
  }

  public ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  public onApplyFilter() {
    this._onFilterUpdate.emit(this._filterModel);
    this._menu.close();
  }

  public onCancel() {
    this._menu.close();
  }

  public onClearFilters(event: any) {
    event.stopPropagation();

    this.value = null;
    this.sort = null;
    this.allSelected = false;

    this._onFilterUpdate.emit(null);

    return false;
  }

  public onSortAsc() {
    this.sort = SortBy.Ascending;

    this._onFilterUpdate.emit(this._filterModel);
    this._menu.close();
  }

  public onSortDesc() {
    this.sort = SortBy.Descending;

    this._onFilterUpdate.emit(this._filterModel);
    this._menu.close();
  }

  public onSearchChange(text: string) {
    this.filterUpdate.next(text);
  }

  public onClearSearch() {
    this.value = null;
    this._onFilterUpdate.emit(this._filterModel);
  }

  public onAllOptionsSelected() {
    this.value = this.filterProperties;
  }

  public onCheckboxModelChange() {
    this.allSelected = false;
  }
}
