import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IKnowledgeSmartLink } from '.';

@Injectable()
export class KnowledgeDatasource {
  private _dataUrl: string = 'reporting-knowledge';

    constructor(private _http: HttpClient) { }

  public getTemplates(daysOffset: number): Observable<IKnowledgeSmartLink[]> {
    return this._http.get<IKnowledgeSmartLink[]>(`${this._dataUrl}/templates?daysOffset=${daysOffset}`);
    }
}
