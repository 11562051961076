import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormValidationDirective } from './Directives/form-validation.directive';
import { FormGroupComponent } from './Components/form-group-component/form-group-component';
import { FormControlComponent } from './Components/form-control-component/form-control-component';
import { MessagesComponent } from './Components/messages/messages.component';
import { ErrorMessageService } from './Services/error-message.service';
import { ErrorMessage } from './Models/ErrorMessage';
import { CUSTOM_ERROR_MESSAGES } from './Tokens/tokens';

@NgModule({
  declarations: [
    FormValidationDirective,
    FormGroupComponent,
    FormControlComponent,
    MessagesComponent
  ],
  imports: [CommonModule],
  exports: [FormValidationDirective, FormGroupComponent, FormControlComponent, MessagesComponent]
})
export class NgBootstrapFormValidationModule {
  public static forRoot(
    customErrorMessages: ErrorMessage[] = []
  ): ModuleWithProviders<NgBootstrapFormValidationModule> {
    if (customErrorMessages.length) {
      console.warn(
        'Deprecation warning: Passing "customErrorMessages" to ' +
          'the "forRoot" method is deprecated and will be removed in a future ' +
          'release. Please use the "CUSTOM_ERROR_MESSAGES" multi-provider, see: ' +
          'https://github.com/third774/ng-bootstrap-form-validation#module-level-custom-errors'
      );
    }

    return {
      ngModule: NgBootstrapFormValidationModule,
      providers: [
        ErrorMessageService,
        {
          provide: CUSTOM_ERROR_MESSAGES,
          useValue: customErrorMessages,
          multi: true
        }
      ]
    };
  }
}
