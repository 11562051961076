import { FilePreviewService } from './file-preview/file-preview.service';
import { NgModule } from '@angular/core';
import { AesEncryption } from './encryption';

@NgModule({
    providers: [
        AesEncryption,
        FilePreviewService,
    ]
})

export class ServicesModule { }
