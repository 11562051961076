<div class="h-100 d-flex flex-column flex-nowrap justify-content-start align-self-stretch" [ngClass]="display"
  style="--item-height: {{itemHeight}}px;">
  <div *ngIf="display === LAYOUT_OPTIONS.TABLE"
    class="row header d-flex flex-row flex-nowrap justify-content-between align-self-stretch">
    <ng-container *ngFor="let cell of cells">
      <div *ngIf="cell.header" class="cell d-flex flex-column flex-nowrap justify-content-start align-self-center"
        [ngClass]="cell.klass">

        <div class="d-flex flex-row flex-nowrap">
          <ng-container *ngTemplateOutlet="cell.header?.template"></ng-container>
          <span class="actions">
            <ng-container *ngTemplateOutlet="cell.actions?.template"></ng-container>
          </span>
        </div>

      </div>
    </ng-container>
  </div>
  <mat-progress-bar automation-id="progress" *ngIf="datasource.isLoading" color="primary" mode="indeterminate">
  </mat-progress-bar>

  <div class="text-center pt-3" *ngIf="!datasource.total && !datasource.isLoading">
    {{ noDataMessage }}
  </div>

  <virtual-scroller #scroll [items]="datasource.stream | async" [ssrChildHeight]="itemHeight"
    (vsEnd)="datasource.fetchMore($event)">
    <div *ngFor="let item of scroll.viewPortItems;" class="row" [ngClass]="getRowClass(item)" (click)="onRowClicked(item)">
      <ng-container *ngIf="item; else loadingItem">
        <ng-container
          *ngTemplateOutlet="displayTemplate; context: { $implicit: item, cells: cellsCache, cellDefaultLayout: cellDefaultLayout }">
        </ng-container>
      </ng-container>
    </div>
  </virtual-scroller>
</div>

<ng-template #loadingItem>
  Loading...
</ng-template>

<ng-template #defaultLayout let-item let-template="cellDefaultLayout">
  <ng-container *ngFor="let cell of cells">
    <ng-container *ngTemplateOutlet="template; context: { $implicit: item, cell: cell }"></ng-container>
  </ng-container>
</ng-template>

<ng-template #cellDefaultLayout let-item let-cell="cell">
  <div class="cell" [ngClass]="cell.klass">
    <small class="label text-truncate w-100">
      <ng-container *ngTemplateOutlet="cell.header?.template"></ng-container>
    </small>
    <span class="value text-truncate w-100">
      <ng-container *ngTemplateOutlet="cell.content.template; context: { $implicit: item }"></ng-container>
    </span>

  </div>
</ng-template>