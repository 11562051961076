import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CONFIG, Configuration } from 'src/environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(
        @Inject(CONFIG) private _config: Configuration,
        public _oauthService: OAuthService) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const update: any = {};

        const isSvgRequest = req.url.includes('.svg');
        const isFilePreviewRequest = req.url.includes(this._config.previewDocumentEndpoint);

        if (isSvgRequest || isFilePreviewRequest) {
            return next.handle(req);
        }

        if (!req.url.includes(this._config.clientSettings.issuer)) {
            update.url = `${this._config.apiEndpoint}${req.url}`;
            update.headers = new HttpHeaders({ 'Authorization': `Bearer ${this._oauthService.getAccessToken()}` });
        }

        const apiReq = req.clone(update);
        return next.handle(apiReq);
    }
}

export const API_PROVIDERS = [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }
];
