import { BaseChartExtension, AppliedDevices } from '../base.ext';

export class BarAnnotationsAlignEndChartExtension extends BaseChartExtension {
    constructor(private _rightOffset: number, appliedDevices: AppliedDevices) {
        super();
        this.appliedDevices = appliedDevices;
     }

    public applyChanges(chartElement: any): void {
        const svg = super.getChartSvg(chartElement);
        if (!svg) {
            return;
        }

        const g = svg.getElementsByTagName('g')[0];
        const annotationsG = g.children[g.children.length - 1];

        const texts = annotationsG.getElementsByTagName('text');

        const chartWidth = chartElement.getBoundingClientRect().width;
        for (let i = 0; i < texts.length; i++) {
            const textWidth = texts[i].getBoundingClientRect().width;
            const newX = +chartWidth - textWidth - this._rightOffset;

            texts[i].setAttribute('x', newX.toString());
        }
    }

}
