import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserService } from '../services/user/user.service';
import { ROLE_ACCESS_LEVELS, UserType } from '../services/user';

@Injectable()
export class SystemAdministratorGuard implements CanActivate {
    constructor(protected _userService: UserService, protected _router: Router) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const systemAdministratorAccessLevel = ROLE_ACCESS_LEVELS.find(ra => ra.userType === UserType.SystemAdministrator).level;

        const maxUserRole = this._userService.roleMaxRoleAccessLevel;

        return maxUserRole >= systemAdministratorAccessLevel;
    }
}
