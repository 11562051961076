import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { concatMap } from 'rxjs/operators';
import { SelectTenantModalComponent } from '../../../../modals/select-tenant';
import {
    ContactFieldsDatasource,
    IService,
    IServiceSetting,
    ServiceKind,
    ServicesDatasource,
    ServiceSettingsDatasource
} from 'src/app/shared';
import { ENCRYPTION } from 'src/app/shared/consts/encryption';
import { SERVICES } from 'src/app/shared/consts/services';
import { AesEncryption } from 'src/app/shared/services/encryption';


export class BaseXeroCallbackComponent {
    constructor(
        private _serviceTagName: string,
        private _tenantType: string,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _serviceDatasource: ServicesDatasource,
        private _serviceSettingsDatasource: ServiceSettingsDatasource,
        private _contactFieldsDatasource: ContactFieldsDatasource,
        private _encryption: AesEncryption,
        private _modalService: NgbModal) { }

    protected registerService(): void {
        this._activatedRoute.queryParams.subscribe(params => {
            const code = params['code'];

            if (!code) {
                this.goToConnections();

                return;
            }

            const foundService = SERVICES.find(s => s.tag === this._serviceTagName);
            const encryptionConstants = ENCRYPTION.find(e => e.tag === this._serviceTagName);

            const service: IService = {
                name: foundService.name,
                kind: ServiceKind.Contacts,
                contactIdentifierFieldName: '',
                type: foundService.type
            };

            this._contactFieldsDatasource.getClientContactFields().subscribe(fields => {
                service.contactIdentifierFieldName = fields.find(f => f.fieldName.toLowerCase() === 'practiceid').fieldName;

                this._serviceDatasource.getToken(foundService.tag, code).subscribe(token => {
                    const refreshToken = token.refreshToken;
                    const tenants = token.tenants.filter(t => t.tenantType === this._tenantType);
                    let tenantId = '';

                    const modal = this._modalService.open(SelectTenantModalComponent, {backdrop: 'static'});
                    modal.componentInstance.tenants = tenants;
                    modal.result.then((result) => {
                        tenantId = result;

                        this._serviceDatasource.create(service).pipe(concatMap(createdService => {
                            const settings: IServiceSetting[] = [{
                                serviceId: createdService.id,
                                name: 'RefreshToken',
                                value: this._encryption.encrypt(refreshToken, encryptionConstants)
                            },
                            {
                                serviceId: createdService.id,
                                name: 'TenantId',
                                value: tenantId
                            },
                            {
                                serviceId: createdService.id,
                                name: 'LastUpdate',
                                value: ''
                            },
                            {
                                serviceId: createdService.id,
                                name: 'DisableFilterByUncDate',
                                value: 'False'
                            }];

                            return this._serviceSettingsDatasource.post(settings);
                        })).subscribe(() => this.goToConnections());
                    });
                });
            });
        });
    }

    private goToConnections() {
      this._router.navigate(['/user/options/firm/connections']);
    }
}
