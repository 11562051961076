import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared';
import { MessageModalComponent } from './message-modal';
import { SelectTenantModalComponent } from './select-tenant';

const DECLARATIONS = [
    MessageModalComponent,
    SelectTenantModalComponent
];

const ENTRY_COMPONENTS = [
];

@NgModule({
    entryComponents: [
        ENTRY_COMPONENTS
    ],
    imports: [
        SharedModule,
        CommonModule
    ],
    declarations: [DECLARATIONS],
    exports: [DECLARATIONS]
})
export class ModalsModule { }
