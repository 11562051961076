import { IIconPack } from '../../controls/icon/icon-pack.interface';

export const FileIconPack: IIconPack = {
    name: 'file',
    icons: [
        'msg',
        'msgx',
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'default'
    ]
};

