import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FirmDetailSettingsDto } from '.';

@Injectable()
export class FirmDetailsOptionsDataSource {
  private _dataUrl: string = 'options/firm';

  constructor(private _http: HttpClient) { }

  public getFirmDetails() {
    return this._http.get<FirmDetailSettingsDto>(`${this._dataUrl}/firm-details`);
  }

  public setFirmDetails(data) {
    return this._http.put(`${this._dataUrl}/firm-details`, data);
  }
}
