import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

// MatBottomSheet has OnPush detection strategy
// You should manually trigger change detection if needed

export abstract class BaseBottomSheetComponent {
    constructor(protected _sheetReference: MatBottomSheetRef) { }

    public cancel() {
        this.close();
    }

    public close(value?: any) {
        this._sheetReference.dismiss(value);
    }
}

