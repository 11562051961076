import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { DateRange, MatCalendar, MatDateRangePicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hn-date-header',
    templateUrl: './date-header.component.html',
    styleUrls: ['./date-header.component.scss'],
    providers: [DatePipe, MatDateRangePicker]
})

export class DateRangeHeaderComponent<D> implements OnDestroy, OnInit {
    public startDate: any;
    public endDate: any;

    public startDateUpdate = new Subject<any>();
    public endDateUpdate = new Subject<any>();

    private _destroyed = new Subject<void>();

    private _dateFormat = 'dd/MM/yyyy';

    private get _calendarStart() {
        const selected = this._calendar.selected as DateRange<any>;
        return selected.start;
    }

    private get _calendarEnd() {
        const selected = this._calendar.selected as DateRange<any>;
        return selected.end;
    }

    constructor(
        private _dateRangePicker: MatDateRangePicker<D>,
        private _calendar: MatCalendar<D>, private _dateAdapter: DateAdapter<D>,
        @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
        private _datePipe: DatePipe,
        cdr: ChangeDetectorRef) {
        _calendar.stateChanges
            .pipe(takeUntil(this._destroyed))
            .subscribe(() => cdr.markForCheck());
    }

    public ngOnInit(): void {
        this.startDateUpdate.pipe(takeUntil(this._destroyed),
            debounceTime(400),
            distinctUntilChanged())
            .subscribe(() => {
                this.updateCalendarRange();
            });

        this.endDateUpdate.pipe(takeUntil(this._destroyed),
            debounceTime(400),
            distinctUntilChanged())
            .subscribe(() => {
                this.updateCalendarRange();
            });

        this.startDate = this._datePipe.transform(this._calendarStart, this._dateFormat);
        this.endDate = this._datePipe.transform(this._calendarEnd, this._dateFormat);

        this._calendar.stateChanges.subscribe(() => {
            this.startDate = this._datePipe.transform(this._calendarStart, this._dateFormat);
            this.endDate = this._datePipe.transform(this._calendarEnd, this._dateFormat);
        });
    }

    public ngOnDestroy() {
        this._destroyed.next();
        this._destroyed.complete();
    }

    get periodLabel() {
        return this._dateAdapter
            .format(this._calendar.activeDate, this._dateFormats.display.monthYearA11yLabel);
    }

    public startDateChange() {
        this.startDateUpdate.next(this.startDate);
    }

    public endDateChange() {
        this.endDateUpdate.next(this.endDate);
    }

    public previousMonthClicked() {
        this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
    }

    public nextMonthClicked() {
        this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
    }

    private updateCalendarRange() {
        const start =  this._dateAdapter.parse(this.startDate, 'DD/MM/YYYY');
        const end = this._dateAdapter.parse(this.endDate, 'DD/MM/YYYY');

        const range = new DateRange(start, end);
        this._calendar.selected = range;
        this._calendar.updateTodaysDate();

        if (start && end && moment(end).isAfter(start)) {
            this._dateRangePicker.select(start as any);
            this._dateRangePicker.select(end as any);
        }
    }
}
