import { BaseChartExtension, AppliedDevices } from '../base.ext';

export class BarRoundedRectanglesChartExtension extends BaseChartExtension {
    constructor(private _radius: number, appliedDevices: AppliedDevices) {
        super();
        this.appliedDevices = appliedDevices;
    }

    public applyChanges(chartElement: any): void {
        const svg = super.getChartSvg(chartElement);
        if (!svg) {
            return;
        }

        const rects = svg.getElementsByTagName('rect');
        for (let i = 0; i < rects.length; i++) {
            rects[i].setAttribute('rx', this._radius.toString());
        }
    }
}
