import { UserType } from './user-type.enum';

export interface IRoleLevel { userType: UserType; level: number; }

export const ROLE_ACCESS_LEVELS: IRoleLevel[] = [
    {
        userType: UserType.SystemAdministrator,
        level: 30
    },
    {
        userType: UserType.Administrator,
        level: 20
    },
    {
        userType: UserType.User,
        level: 10
    }
];

