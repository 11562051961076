import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'select-tenant',
    templateUrl: './select-tenant.component.html',
    styleUrls: ['./select-tenant.component.scss']
})
export class SelectTenantModalComponent implements OnInit {

    @Input() public tenants = [];
    public selectedTenant;
    public modalEnabled = false;


    constructor(public activeModal: NgbActiveModal) { }

    public ngOnInit() {
        console.log('Opened modal');
        this.selectedTenant = this.tenants[0];
        this.modalEnabled = true;
    }

    public selectTenant() {
        this.activeModal.close(this.selectedTenant.tenantId);
    }
}
