import { BaseChartExtension, AppliedDevices } from '../base.ext';

export class BarOffsetChartExtension extends BaseChartExtension {
    private _startX: number;
    private _startWidth: number;

    private _svgWidth: number;

    private _barWidths = [];

    constructor(private _leftOffset: number, private _fromZero = false, appliedDevices: AppliedDevices) {
        super();
        this.appliedDevices = appliedDevices;
    }

    public applyChanges(chartElement: any): void {
        const svg = super.getChartSvg(chartElement);
        if (!svg) {
            return;
        }

        const svgWidth = +svg.getAttribute('width');
        const g = svg.getElementsByTagName('g')[0];
        const rectRenderer = svg.children[0].getElementsByTagName('rect')[0];

        if (!svgWidth) {
            this._svgWidth = svgWidth;
        }

        if (this._svgWidth !== svgWidth) {
            this._svgWidth = svgWidth;
            this._startX = null;
            this._startWidth = null;
            this._barWidths = [];
        }

        if (!this._startX) {
            this._startX = this._fromZero ? 0 : +rectRenderer.getAttribute('x');
        }

        if (!this._startWidth) {
            this._startWidth = +rectRenderer.getAttribute('width');
        }

        rectRenderer.setAttribute('x', this._startX + this._leftOffset);
        rectRenderer.setAttribute('width', this._startWidth - (this._leftOffset * 2));

        const barRectangles = g.children[1].getElementsByTagName('rect');

        for (let i = 0; i < barRectangles.length; i++) {

            if (!this._barWidths[i]) {
                this._barWidths[i] = +barRectangles[i].getAttribute('width');
            }

            barRectangles[i].setAttribute('x', this._startX + this._leftOffset);

            const currentWidth = +this._barWidths[i];
            const rightOffset = currentWidth * 5 / 100;
            barRectangles[i].setAttribute('width', currentWidth - rightOffset);

            if (+barRectangles[i].getAttribute('width') >= this._startWidth - (this._leftOffset * 2)) {
                barRectangles[i].setAttribute('width', this._startWidth - (this._leftOffset * 2));
            }
        }
    }
}
