import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services';

@Component({
    selector: 'hn-callback',
    templateUrl: './callback.component.html'
})

export class CallbackComponent implements OnInit {
    constructor(private _userService: UserService) { }

    public ngOnInit() {
        this._userService.registerToken();
    }
}
