import { Component, Input } from '@angular/core';

@Component({
  selector: 'crs-messages',
  template: `
    <span class="form-text" *ngFor="let message of messages()">{{message}}</span>
  `
})
export class MessagesComponent {
  @Input() public messages = () => [];

  constructor() {}
}
