import { Component, Input, ContentChild, TemplateRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DefaultValueAccessor } from '../forms/value-accessors/default-value-accessor';

@Component({
  selector: 'hn-option-select',
  templateUrl: './option-select.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OptionSelectComponent),
    multi: true
  }]
})

export class OptionSelectComponent extends DefaultValueAccessor<any> implements ControlValueAccessor {
  @Input('options')
  public options: any[] = [];

  @ContentChild(TemplateRef)
  public optionTemplate: TemplateRef<any>;

  public onOptionSelect(option: any): void {
    this.value = option;
  }
}
