import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IHowNowField } from './models/hownow-field.interface';

@Injectable()
export class ContactFieldsDatasource {
    private _dataUrl: string = 'contact-fields';

    constructor(private _http: HttpClient) { }

    public getClientContactFields(): Observable<IHowNowField[]> {
        return this._http.get<IHowNowField[]>(`${this._dataUrl}/client`);
    }
}
