import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-office',
  templateUrl: './office.component.html',
  styleUrls: ['./office.component.scss']
})
export class OfficeComponent implements OnInit {
  public fileId: string;
  public action: string;
  public isLoadingDocument: boolean;
  public isError: boolean;

  constructor(private route: ActivatedRoute) { }

  public ngOnInit(): void {
    this.isLoadingDocument = true;
    this.fileId = this.route.snapshot.paramMap.get('recordId');
    this.action = this.route.snapshot.paramMap.get('action');
    if (this.action !== 'view' && this.action !== 'edit') {
      this.action = 'view';
    }
  }

  public onDocumentLoaded() {
    this.isLoadingDocument = false;
  }

  public onErrorOccured() {
    this.isError = true;
    this.isLoadingDocument = false;
  }
}
