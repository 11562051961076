import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DefaultValueAccessor } from '../../forms/value-accessors/default-value-accessor';
import { CheckboxComponent } from '../checkbox.component';

@Component({
  selector: 'hn-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxGroupComponent),
    multi: true
  }]
})

export class CheckboxGroupComponent extends DefaultValueAccessor<any> implements ControlValueAccessor {
  @Input('preserve-values')
  private _preserveValues: boolean = false;

  private _checkBoxes: CheckboxComponent[] = [];

  public onModelChanged() {
    if (this._preserveValues) {
      const visibleValues = this._checkBoxes.filter(checkbox => checkbox.value)
        .map(checkbox => checkbox.setValue);

      const preservedValues = [];
      this.value.forEach(value => {
        const responsibleCheckbox = this._checkBoxes.find(checkbox => checkbox.setValue === value);

        if (!responsibleCheckbox) {
          preservedValues.push(value);
        }
      });

      this.value = [...visibleValues, ...preservedValues];
      return;
    }

    this.value = this._checkBoxes.filter(checkbox => checkbox.value)
      .map(checkbox => checkbox.setValue);
  }

  public reset() {
    this.value = [];
    this._checkBoxes.forEach(checkBox => {
      checkBox.resetValue();
    });

    this.onModelChanged();
  }

  public writeValue(values: any[]) {
    super.writeValue(values);

    if (!values || values.length === 0) {
      this._checkBoxes.forEach(checkbox => {
        checkbox.markAsUnChecked();
      });
    } else {
      values.forEach(value => {
        this._checkBoxes.forEach(checkbox => {
          if (checkbox.setValue === value) {
            checkbox.markAsChecked();
          }
        });
      });
    }
  }

  public addCheckbox(checkbox: CheckboxComponent) {
    this._checkBoxes.push(checkbox);
  }

  public removeCheckbox(checkbox: CheckboxComponent) {
    const index = this._checkBoxes.indexOf(checkbox);
    if (index >= 0 && index < this._checkBoxes.length) {
      this._checkBoxes.splice(index, 1);
    }
  }
}

