import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ITableResponse } from '../../../core/controls/table-view/table-response.interface';
import { IEmailSentiment, IUserRecord, IUserRecordSmartLink } from '.';

@Injectable()
export class RecordsDatasource {
    private _dataUrl: string = 'reporting-records';

    constructor(private _http: HttpClient) { }

    public getRecords(take: number, skip: number, filters: any): Observable<ITableResponse<any>> {
         filters.take = take;
         filters.skip = skip;

        return this._http.get<any>(`records`, { params: { 'query': JSON.stringify(filters) } });
    }

    public getRecordTypes() {
      return this._http.get<string[]>(`records/record-types`);
  }

    public getRecordStatuses() {
      return this._http.get<string[]>(`records/record-statuses`);
    }

    public download(recordId: number) {
        return this._http.get(`records/${recordId}/download`, { responseType: 'blob' });
    }

    public getPublicUrl(Id: string) {
        return this._http.get(`records/${Id}/url`, { responseType: 'text' });
    }

    public getEmailSentiment(daysOffset: number): Observable<IEmailSentiment[]> {
        return this._http.get<IEmailSentiment[]>(`${this._dataUrl}/negative-sentiment-emails?daysOffset=${daysOffset}`);
    }

    public getUserRecords(daysOffset: number): Observable<IUserRecord[]> {
        return this._http.get<IUserRecord[]>(`${this._dataUrl}/user-records?daysOffset=${daysOffset}`);
    }

    public getSmartLinks(daysOffset: number, userId?: number, skip?: number, take?: number):
        Observable<ITableResponse<IUserRecordSmartLink>> {
        const userIdSection = userId ? `&memberId=${userId}` : '';
        const url = `${this._dataUrl}/smart-links/records/${daysOffset}?&skip=${skip}&take=${take}${userIdSection}`;

        return this._http.get<ITableResponse<IUserRecordSmartLink>>(url);
    }
}
