import { Component, Inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { COMPILER_CONTENT } from '../../../services/compiler/compiler.service';
import { SlideOutViewComponent } from '../slide-out-view/slide-out-view.component';

export const CLOSE_OPEN_EFFECT_SLIDE_OUT_DELAY = 300;

@Component({
  selector: 'hn-slide-out-container',
  template: `<ng-content #content></ng-content>`
})

export class SlideOutViewContainerComponent {
  private static _openedSlideOutView: SlideOutViewContainerComponent;

  private _subject: Subject<any> = new Subject();
  private _openTimeout;

  constructor(@Inject(COMPILER_CONTENT) private _slideOutView: SlideOutViewComponent) {
  }

  public open(): Observable<any> {
    this.removeIfExists();

    SlideOutViewContainerComponent._openedSlideOutView = this;

    this._slideOutView.onClose.subscribe(result => {
      this._subject.next(result);
      this.remove();
    });

    this._slideOutView._concreteSlideOutType.onClose.subscribe(result => {
      this._subject.next(result);
      this.remove();
    });

    this._slideOutView._concreteSlideOutType.isOpened = true;

    return this._subject.asObservable();
  }

  private remove() {
    this._slideOutView._concreteSlideOutType.isOpened = false;

    clearTimeout(this._openTimeout);

    this._openTimeout = setTimeout(() => {
      this._subject.complete();
    }, CLOSE_OPEN_EFFECT_SLIDE_OUT_DELAY);
  }

  private removeIfExists() {
    if (SlideOutViewContainerComponent._openedSlideOutView) {
      SlideOutViewContainerComponent._openedSlideOutView._subject.complete();
    }
  }
}
