import {
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  Input,
  AfterContentInit,
  QueryList,
  TemplateRef,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TableViewCellDirective } from './table-view-cell/table-view-cell.directive';
import { TableViewDatasource } from './table-view.datasource';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

const LAYOUT_OPTIONS = {
  TABLE: 'table',
  LIST: 'list',
  TILES: 'tiles'
};

@Directive({
  selector: '[hnTableViewTableLayout]'
})
export class TableViewTableLayoutDirective {
  constructor(public template: TemplateRef<any>) { }
}

@Directive({
  selector: '[hnTableViewListLayout]'
})
export class TableViewListLayoutDirective {
  constructor(public template: TemplateRef<any>) { }
}

@Directive({
  selector: '[hnTableViewTilesLayout]'
})
export class TableViewTilesLayoutDirective {
  constructor(public template: TemplateRef<any>) { }
}

@Component({
  selector: 'hn-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss']
})
export class TableViewComponent implements AfterContentInit, OnDestroy {
  public LAYOUT_OPTIONS = LAYOUT_OPTIONS;
  public display: string = LAYOUT_OPTIONS.TABLE;
  public displayTemplate: TemplateRef<any>;
  public cellsCache: any;
  public itemHeight: any;
  private _unsubscribeAll = new Subject();

  @Output('row-click')
  public rowClick = new EventEmitter<any>();

  @Input('datasource')
  public datasource: TableViewDatasource;

  @Input('item-heights')
  public itemHeights: any;

  @Input('no-data-message')
  public noDataMessage: string = 'No data';

  @ContentChildren(TableViewCellDirective)
  public cells: QueryList<TableViewCellDirective>;

  @ContentChild(TableViewTableLayoutDirective)
  public tableLayout: TableViewTableLayoutDirective;

  @ContentChild(TableViewListLayoutDirective)
  public listLayout: TableViewListLayoutDirective;

  @ContentChild(TableViewTilesLayoutDirective)
  public tilesLayout: TableViewTilesLayoutDirective;

  @ViewChild('defaultLayout', { read: TemplateRef, static: true })
  private _defaultLayout: TemplateRef<any>;

  @Input('row-class-fn')
  private _rowClassFn: any = (item) => { }

  constructor(private _breakpointObserver: BreakpointObserver) {
  }

  public ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    this.datasource.destroy();
  }

  public async ngAfterContentInit() {
    this._breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium
    ])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.refresh(result.breakpoints);
      });

    this.cellsCache = this.cells.reduce((obj, cell) => {
      obj[cell.name] = cell;
      return obj;
    }, {});
  }

  public refresh(breakpoints) {
    if (breakpoints[Breakpoints.XSmall]) {
      this.display = LAYOUT_OPTIONS.TILES;
      this.displayTemplate = this.tilesLayout?.template;
    } else if (breakpoints[Breakpoints.Small] || breakpoints[Breakpoints.Medium]) {
      this.display = LAYOUT_OPTIONS.LIST;
      this.displayTemplate = this.listLayout?.template;
    } else {
      this.display = LAYOUT_OPTIONS.TABLE;
      this.displayTemplate = this.tableLayout?.template;
    }

    if (!this.displayTemplate) {
      this.display = LAYOUT_OPTIONS.TABLE;
      this.displayTemplate = this._defaultLayout;
    }

    this.itemHeight = this.itemHeights[this.display] || this.itemHeights.default;
  }

  public getRowClass(item: any) {
    if (!item) {
      return;
    }

    if (!item._class) {
      item._class = { ...this._rowClassFn && this._rowClassFn(item) };
    }
    return item._class;
  }

  public onRowClicked(item: any) {
    this.rowClick.emit(item);
  }
}
