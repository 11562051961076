import { Injectable } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { TABLET_MOBILE_QUERY } from '../../consts/consts';

@Injectable()
export class ScreenResolutionService {
    public isMobile(): boolean {
        return this._media.matchMedia(TABLET_MOBILE_QUERY).matches;
    }

    constructor(private _media: MediaMatcher) {
    }
}
