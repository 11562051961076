import { Inject, Injectable } from '@angular/core';
import { CONFIG, Configuration } from 'src/environments/environment';

@Injectable()
export class FilePreviewService {
    constructor(@Inject(CONFIG) private _config: Configuration) {}

    public generateUrl(url, name) {
        const primaryColor = '0C9EFF';

        return `${this._config.previewDocumentEndpoint}?color=${primaryColor}&title=${name}&url=${encodeURIComponent(url)}`;
    }
}
