import { NgModule } from '@angular/core';
import { API_PROVIDERS } from './interceptors';
import { GUARDS } from './guards';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ServicesModule } from './services';
import { WopiHostComponent } from './controls';
import { CoreModule } from '../core/core.module';
import { NgSelectModule } from '@ng-select/ng-select';

const IMPORTS_EXPORTS = [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ServicesModule,
    CoreModule,
    NgSelectModule
];

const PIPES = [
];

const DECLARATIONS = [
    WopiHostComponent,
    PIPES
];

@NgModule({
    declarations: [DECLARATIONS],
    imports: [
        IMPORTS_EXPORTS
    ],
    providers: [
        GUARDS,
        API_PROVIDERS
    ],
    exports: [
        IMPORTS_EXPORTS,
        DECLARATIONS
    ]
})
export class SharedModule { }
