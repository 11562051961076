import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'hn-indicator',
    templateUrl: './indicator.component.html',
    styleUrls: ['./indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class IndicatorComponent {
    @Input('color')
    public color = 'primary';

    @Input('hide-outline')
    public hideOutline = false;

    @Input('size')
    @HostBinding('style.width')
    @HostBinding('style.height')
    public size: string = '1rem';
}
