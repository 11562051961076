import { IAvailableService } from '..';

export const SERVICES: IAvailableService[] = [
    {
        name: 'Xero Practice Manager (V3.1 API)',
        imageUrl: 'assets/images/services/xpm.png',
        tag: 'xpm',
        type: 'BusinessFitness.DataSync.XeroPracticeManager'
    },
    {
        name: 'WorkflowMax (V3 API)',
        imageUrl: 'assets/images/services/wfm.png',
        tag: 'wfm',
        type: 'BusinessFitness.DataSync.WorkflowMax'
    }
];
