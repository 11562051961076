import { Component, Inject } from '@angular/core';
import { CONFIG, Configuration } from '../../../../environments/environment';

@Component({
    selector: 'hn-products-navigation',
    templateUrl: './products-navigation.component.html'
})

export class ProductsNavigationComponent {
    public isMenuOpened = false;

    public productGroups = [
        {
            name: 'Document management & workflow',
            products: [
                {
                    name: 'HowNow',
                    icon: 'hownow',
                    url: this._config.productUrls.howNow
                },
                {
                    name: 'HowNow Portal',
                    icon: 'hownow',
                    url: this._config.productUrls.howNowPortal
                },
                {
                    name: 'HowNow Workflow',
                    icon: 'hownow',
                    url: this._config.productUrls.howNowWorkflow
                }
            ]
        },
        {
            name: 'Compliance',
            products: [
                {
                    name: 'Reportance',
                    icon: 'reportance',
                    url: this._config.productUrls.reportance
                },
                {
                    name: 'Smart Suite',
                    icon: 'smart',
                    url: this._config.productUrls.smartSuite,
                },
                {
                    name: 'Workpapers',
                    icon: 'smart',
                    url: this._config.productUrls.workpapers,
                    level: 2
                },
                {
                    name: 'Templates',
                    icon: 'smart',
                    url: this._config.productUrls.templates,
                    level: 2
                }
            ]
        },
        {
            name: 'Industry reports',
            products: [
                {
                    name: 'Good bad ugly',
                    icon: 'badugly',
                    url: this._config.productUrls.goodBadUgly
                }
            ]
        }
    ];

    constructor(@Inject(CONFIG) private _config: Configuration) {}

    public onMenuChange(status: boolean) {
        this.isMenuOpened = status;
    }

    public navigateTo(url) {
        if (url) {
            window.location = url;
        }
    }
}
