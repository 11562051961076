import {
  Component,
  ContentChildren,
  ContentChild,
  ElementRef,
  HostBinding,
  Input,
  QueryList,
  OnInit,
  AfterContentInit
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { ErrorMessage } from '../..';
import { ErrorMessageService } from '../../Services/error-message.service';
import { MessagesComponent } from '../messages/messages.component';

@Component({
  // tslint:disable:component-selector
  selector: '.form-group',
  template: `
    <ng-content></ng-content>
    <crs-messages class="invalid-feedback" *ngIf="!messagesBlock" [messages]="messages"></crs-messages>
  `
})
export class FormGroupComponent implements OnInit, AfterContentInit {

  @ContentChildren(NgControl, { descendants: true }) private FormControls: QueryList<NgControl>;

  @Input() private customErrorMessages: ErrorMessage[] = [];
  @Input() private validationDisabled = false;

  @HostBinding('class.is-invalid')
  get hasErrors() {
    return (
      this.FormControls.some(c => c.invalid && c.touched) &&
      !this.validationDisabled
    );
  }

  @ContentChild(MessagesComponent, {static: false}) public messagesBlock: MessagesComponent;

  private errorMessages: ErrorMessage[];
  public messages = () => this.getMessages();

  constructor(
    private elRef: ElementRef,
    private errorMessageService: ErrorMessageService
  ) {}

  public ngAfterContentInit() {
    if (this.messagesBlock) {
      this.messagesBlock.messages = this.messages;
    }
  }

  public ngOnInit() {
    this.errorMessages = this.errorMessageService.errorMessages
      .concat(this.customErrorMessages)
      .reverse();
  }

  get label() {
    const label = this.elRef.nativeElement.querySelector('label');
    return label && label.textContent ? label.textContent.trim() : 'This field';
  }

  get isTouched() {
    return this.FormControls.some(c => c.touched);
  }

  private getMessages(): string[] {
    const messages = [];
    if (!this.isTouched || this.validationDisabled) {
      return messages;
    }

    this.FormControls
      .filter(c => !c.valid && !!c.errors)
      .forEach(control => {
        Object.keys(control.errors).forEach(key => {
          const error = this.errorMessages.find(err => err.error === key);
          if (!error) {
            return;
          }
          messages.push(error.format(this.label, control.errors[key]));
        });
      });

    return messages;
  }
}


