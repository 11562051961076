import * as crypto from 'crypto-js';
import { IEncryptionConstants } from 'src/app/shared/models';
import { Injectable } from '@angular/core';

const encryptionType = 'aes-256-cbc';
const encryptionEncoding = 'base64';
const bufferEncryption = 'utf8';

@Injectable()
export class AesEncryption {
    public encrypt(plainText: string, keys: IEncryptionConstants): string {
        const key = crypto.enc.Utf8.parse(keys.key);
        const iv = crypto.enc.Utf8.parse(keys.iv);
        const bytes = crypto.AES.encrypt(plainText, key, {iv: iv});
        const encryptedText = bytes.toString();
        return encryptedText;
    }

    public decrypt(encryptedText: string, keys: IEncryptionConstants): any {
        const key = crypto.enc.Utf8.parse(keys.key);
        const iv = crypto.enc.Utf8.parse(keys.iv);
        const bytes = crypto.AES.decrypt(encryptedText, key, {iv: iv});
        const encrypted = bytes.toString(crypto.enc.Utf8);
        return encrypted;
    }
}
