import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserService } from '../services/user/user.service';

@Injectable()
export class AuthorizedGuard implements CanActivate {
    constructor(protected _userService: UserService, protected _router: Router) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const isLoggedIn = this._userService.isLoggedIn();

        if (!isLoggedIn) {
            const returnUrl = state.url || '/user/dashboard';

            this._userService.startAuthentication(returnUrl);
        }

        return isLoggedIn;
    }
}
