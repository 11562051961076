import { BaseChartExtension, AppliedDevices } from '../base.ext';

export class BarLabelsAlignStartChartExtension extends BaseChartExtension {

    constructor(private _leftOffset: number,  appliedDevices: AppliedDevices) {
        super();
        this.appliedDevices = appliedDevices;
     }

    public applyChanges(chartElement: any): void {
        const svg = super.getChartSvg(chartElement);
        if (!svg) {
            return;
        }

        const g = svg.getElementsByTagName('g')[0];
        const labelsG = g.children[g.children.length - 2];

        const texts = labelsG.getElementsByTagName('text');

        for (let i = 0; i < texts.length; i++) {
            texts[i].setAttribute('x', this._leftOffset.toString());
            texts[i].setAttribute('text-anchor', 'start');
        }
    }
}
