import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AccessCodeDatasource {
    private _dataUrl: string = 'support';

    constructor(private _http: HttpClient) { }

    public validateAdvanced(code: string): Observable<any> {
        return this._http.get<string>(`${this._dataUrl}/advanced-code/${code}/validate`);
    }
}
