import {
  Directive,
  Input,
  HostListener,
  OnInit,
  EventEmitter,
  Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: 'form[formGroup]'
})
export class FormValidationDirective implements OnInit {
  @Input() private formGroup: FormGroup;
  @Output() public validSubmit = new EventEmitter<any>();

  @HostListener('submit')
  public onSubmit() {
    this.markAsTouchedAndDirty(this.formGroup);
    if (this.formGroup.valid) {
      this.validSubmit.emit(this.formGroup.value);
    }
  }

  public markAsTouchedAndDirty(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key];
      if (control instanceof FormGroup) {
        this.markAsTouchedAndDirty(control);
      } else if (control.enabled) {
        control.markAsDirty();
        control.markAsTouched();
        control.updateValueAndValidity();
      }
    });
  }

  constructor() {}

  public ngOnInit() {}
}
