import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IIconPack } from '.';
import { IconComponent } from './icon.component';
import { ICONPACK_CONFIG_TOKEN, IconService } from './icon.service';

@NgModule({
    imports: [MatIconModule],
    declarations: [IconComponent],
    exports: [MatIconModule, IconComponent],
    providers: [IconService],
})
export class IconModule {

    constructor(private _iconService: IconService) {
        this._iconService.registerIconPacks();
    }

    public static register(pack: IIconPack) {
        return {
            ngModule: IconModule,
            providers: [
                {
                    provide: ICONPACK_CONFIG_TOKEN,
                    useValue: pack,
                    multi: true
                }
            ]
        };
    }
}
