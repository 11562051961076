import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { IPageInfo } from 'ngx-virtual-scroller';
import { ITableResponse } from './table-response.interface';

export class TableViewDatasource {
  private _cachedData = [];
  private _fetchedPages = new Set<number>();
  public stream = new BehaviorSubject<(string | undefined)[]>(this._cachedData);
  private _fetchSubject: Subject<any> = new Subject();
  private _fetchSubscriptions = new Map<number, Subscription>();
  public isLoading = true;

  private _unsubscribeAll = new Subject();

  public get pageSize(): number {
    return this._pageSize;
  }

  public set pageSize(value: number) {
    this._pageSize = value;
  }

  public get limit(): number {
    return this._limit;
  }

  public set limit(value: number) {
    this._limit = value;
  }

  public get total(): number {
    return this._cachedData.length;
  }

  public get cachedData() {
    return this._cachedData;
  }

  constructor(
    private _fetchData: (params) => Observable<ITableResponse<any>>,
    private _pageSize?: number,
    private _limit?: number,
  ) {
    this._pageSize = this._pageSize || 100;
    this._fetchSubject
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(500),
        distinctUntilChanged())
      .subscribe(pages => {
        pages.forEach(p => this._fetchPage(p));
      });
    this.refresh();
  }

  public refresh() {
    this._cachedData = [];
    this._fetchedPages.clear();
    this._fetchPage(0);
  }

  public fetchMore(event: IPageInfo) {
    const startPage = this._getPageForIndex(event.startIndex);
    const endPage = this._getPageForIndex(event.endIndex);
    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    this._fetchSubject.next(pages);
  }

  private _getPageForIndex(index: number): number {
    return Math.floor(Math.max(index, 0) / this._pageSize);
  }

  private _fetchPage(page: number) {
    if (this._fetchedPages.has(page)) {
      return;
    }
    this._fetchedPages.add(page);

    const limit = this._limit || Number.MAX_SAFE_INTEGER;
    const startRow = Math.min(page * this._pageSize, limit);
    const endRow = Math.min((page + 1) * this._pageSize, limit);

    if (startRow >= limit) {
      return;
    }

    if (this._fetchSubscriptions.has(page)) {
      this._fetchSubscriptions.get(page).unsubscribe();
    }

    this.isLoading = true;
    this._fetchSubscriptions.set(page, this._fetchData({ top: endRow - startRow, skip: startRow })
      .subscribe(result => {
        let total = result.total > limit ? limit : result.total;
        if (total) {
          if (!this._cachedData.length) {
            this._cachedData = Array.from({ length: total || (result.items.length + 1) });
          }
        } else {
          if (!this._cachedData.length) {
            this._cachedData = [undefined];
          }
          if (result.items.length) {
            total = this._cachedData.length + result.items.length;
            this._cachedData = this._cachedData.concat(Array.from({ length: total - this._cachedData.length }));
          } else {
            this._cachedData.pop();
          }
        }

        this._cachedData.splice(page * this._pageSize, this._pageSize, ...result.items);
        this.stream.next(this._cachedData);

        this.isLoading = false;
      }));
  }

  public destroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    this._fetchedPages = undefined;
    this._cachedData = undefined;

    this.stream.complete();
    this._fetchSubject.complete();

    this._fetchSubscriptions.forEach(subscription  => {
      subscription.unsubscribe();
    });

    this._fetchSubscriptions = undefined;
  }
}
