import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ITopClient } from './models/top-client.interface';
import { ITableResponse } from '../../../core/controls/table-view/table-response.interface';
import { IClient, IRecordSmartLink, IUpcomingBirthdays } from '.';

@Injectable()
export class ClientsDatasource {
    private _dataUrl: string = 'reporting-clients';

    constructor(private _http: HttpClient) { }

    public getClients(take: number, skip: number, name: string): Observable<ITableResponse<IClient>> {
        return this._http.get<any>(`clients`,  {
            params: {
                take: take + '',
                skip: skip + '',
                name: name || ''
            }
        });
    }

    public getTopClients(daysOffset: number, onlyPortalRecords: boolean = false): Observable<ITopClient[]> {
        const onlyPortalRecordsQuery = onlyPortalRecords ? '&portalRecordsOnly=true' : '';
        return this._http.get<ITopClient[]>(`${this._dataUrl}/top-clients?daysOffset=${daysOffset}${onlyPortalRecordsQuery}`);
    }

    public getMyReviews(reviewType: string = 'default'): Observable<IRecordSmartLink[]> {
        return this._http.get<IRecordSmartLink[]>(`${this._dataUrl}/my-reviews/${reviewType}`);
    }

    public getSmartLinks(daysOffset: number, clientId?: number, skip?: number, take?: number):
        Observable<ITableResponse<IRecordSmartLink>> {
        const clientIdSection = clientId ? `&clientId=${clientId}` : '';

        const url = `${this._dataUrl}/smart-links/${daysOffset}?&skip=${skip}&take=${take}${clientIdSection}`;
        return this._http.get<ITableResponse<IRecordSmartLink>>(url);
    }

    public getUpcomingBirthDays(): Observable<IUpcomingBirthdays[]> {
        return this._http.get<IUpcomingBirthdays[]>(`${this._dataUrl}/upcoming-birthdays`);
    }
}
