import { NgModule } from '@angular/core';
import { IconModule } from './controls';
import { SafePipe } from './pipes/safe/safe.pipe';
import { ServicesIconPack } from './consts/icons/services.icons';
import { SharedIconPack } from './consts/icons/shared.icons';
import { FileIconPack } from './consts/icons/file.icons';
import { CoreServicesModule } from './services/core-services.module';
import { CoreControlsModule } from './controls/controls.module';
import { NgBootstrapFormValidationModule } from './validation';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';

const IMPORTS_EXPORTS = [
    CoreServicesModule,
    CoreControlsModule,
    NgBootstrapFormValidationModule
];

const PIPES = [
    SafePipe,
    EnumToArrayPipe
];

const DECLARATIONS = [
    PIPES
];

@NgModule({
    declarations: [DECLARATIONS],
    imports: [
        IMPORTS_EXPORTS,
        IconModule.register(SharedIconPack),
        IconModule.register(FileIconPack),
        IconModule.register(ServicesIconPack)
    ],
    providers: [
    ],
    exports: [
        IMPORTS_EXPORTS,
        DECLARATIONS,
        IconModule
    ]
})
export class CoreModule { }
