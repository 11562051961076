import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IServiceFieldMapping } from '.';

@Injectable()
export class FieldMappingsDatasource {
    private _dataUrl: string = 'field-mappings';

    constructor(private _http: HttpClient) { }

    public getForService(serviceId: number): Observable<IServiceFieldMapping[]> {
        return this._http.get<IServiceFieldMapping[]>(`${this._dataUrl}/service/${serviceId}`);
    }

    public putForService(serviceId: number, fieldMappings: IServiceFieldMapping[]): Observable<IServiceFieldMapping[]> {
        return this._http.put<IServiceFieldMapping[]>(`${this._dataUrl}/service/${serviceId}`, fieldMappings);
    }
}
