<div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" class="h-100" (menuOpened)="onMenuOpen()"
  (menuClosed)="onMenuClose()">
  <div class="h-100" #container>
    <ng-content select="[header]">
    </ng-content>
  </div>
</div>

<mat-menu [class]="menuClass" #menu="matMenu">
  <div (click)="onClick($event)" [style.width]="menuWidth">
    <ng-content select="[menu]">
    </ng-content>
  </div>
</mat-menu>
