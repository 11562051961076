import {
  Injectable,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  ComponentRef,
  Type,
  InjectionToken,
  StaticProvider
} from '@angular/core';

export const COMPILER_CONTENT = new InjectionToken<any>('COMPILER_CONTENT');

@Injectable()
export class Compiler {
  constructor(
    private _applicationRef: ApplicationRef,
    private _injector: Injector,
    private _resolver: ComponentFactoryResolver
  ) {
  }

  public createComponentRef<T, U>(concreteType: Type<T>, content?: Type<U>, providers?: StaticProvider[]): ComponentRef<T> {
    const contentRef = this.resolveNgContentRef(content, providers);
    const componentRef = this.resolveComponentRef(concreteType, contentRef, providers);

    this._applicationRef.attachView(componentRef.hostView);

    componentRef.hostView.detectChanges();

    return componentRef;
  }

  public destroyComponentRef(componentRef: ComponentRef<any>): void {
    this._applicationRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }

  private resolveComponentRef<T, U>(concreteType: Type<T>, contentRef: ComponentRef<U>, providers?: StaticProvider[]): ComponentRef<T> {
    const factory = this._resolver.resolveComponentFactory<T>(<any>concreteType);
    if (contentRef) {
      const injector = Injector.create({
        providers: [
          { provide: COMPILER_CONTENT, useValue: contentRef.instance },
          ...(providers || [])
      ],
        parent: this._injector
      });
      return factory.create(injector, [[contentRef.location.nativeElement]]);
    }

    return factory.create(this._injector);
  }

  private resolveNgContentRef<U>(content: Type<U>, providers: StaticProvider[]): ComponentRef<U> {
    if (!content) {
      return undefined;
    }

    const factory = this._resolver.resolveComponentFactory(content);
    const injector = Injector.create({
      providers: providers || [],
      parent: this._injector
    });
    const contentRef = factory.create(injector);
    this._applicationRef.attachView(contentRef.hostView);
    contentRef.hostView.detectChanges();

    return contentRef;
  }
}
