import { BaseChartExtension, AppliedDevices } from '../base.ext';

export class BarLabelsFullSizeChartExtension extends BaseChartExtension {

    constructor(private _labels: string[], appliedDevices: AppliedDevices) {
        super();
        this.appliedDevices = appliedDevices;
    }

    public applyChanges(chartElement: any): void {
        const svg = super.getChartSvg(chartElement);
        if (!svg) {
            return;
        }

        const g = svg.getElementsByTagName('g')[0];
        const labelsG = g.children[g.children.length - 2];

        for (let i = 0; i < labelsG.children.length; i++) {
            const texts = labelsG.children[i].getElementsByTagName('text');

            for (let j = 0; j < texts.length; j++) {
                if (j === 0) {
                    texts[j].textContent = this._labels[i];
                } else {
                    texts[j].style.display = 'none';
                }
            }

        }
    }
}
