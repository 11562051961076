import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BaseBottomSheetComponent } from './base-bottom-sheet.component';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BottomSheetService {
    constructor(private _matBottomSheet: MatBottomSheet) {
    }

    public open<T extends BaseBottomSheetComponent>(sheetComponent: ComponentType<T>, data?: any, rounded = true): Observable<any> {
        const sheetOptions = {
            data,
            panelClass: rounded ? 'sheet-rounded-top' : ''
        };

        return this._matBottomSheet.open(sheetComponent, sheetOptions).afterDismissed().pipe(filter(res => res));
    }
}
