import {Injectable} from '@angular/core';

@Injectable()
export class FileService {
  public saveByteArray(fileName, byte) {
    const blob = new Blob([byte], { type: 'octet-stream' });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;

    link.click();

    link.remove();
  }
}
