import { IEncryptionConstants } from 'src/app/shared';

export const ENCRYPTION: IEncryptionConstants[] = [
    {
        tag: 'xpm',
        key: '9BAD7ABC33B98BFD5C9165DA5329F54C',
        iv: 'DCFDA1B7A37A2D1A'
    },
    {
        tag: 'wfm',
        key: '1DC83F535371E31D884E8D369517BA85',
        iv: '46D258E625F858EE'
    }
];
