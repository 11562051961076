export abstract class StorageBase {

  protected abstract storage: any;

  public set(key: string, value: any) {
    this.storage.setItem(`${key}`, JSON.stringify(value));
  }

  public get(key: string): any {
    return JSON.parse(this.storage.getItem(`${key}`));
  }

  public remove(key: string): any {
    return this.storage.removeItem(`${key}`);
  }

  public clear(): void {
    this.storage.clear();
  }
}
