import { AuthorizedGuard } from './authorized.guard';
import { AdministratorGuard } from './administrator.guard';
import { SystemAdministratorGuard } from './system-administrator.guard';

export * from './authorized.guard';

export const GUARDS = [
    AuthorizedGuard,
    AdministratorGuard,
    SystemAdministratorGuard
];
