<hn-dropdown-menu fullSize="true" [class.disabled]="disabled">
  <ng-container header>
    <div class="form-control d-flex align-items-center p-2">
      <div class="flex-grow-1 d-flex align-items-center">
        <span class="color-box mr-2" [style.backgroundColor]="value"></span> {{valueText}}
      </div>
      <hn-icon icon="keyboard_arrow_down" size="1" color="secondary">
      </hn-icon>
    </div>
  </ng-container>
  <ng-container menu>
    <button mat-menu-item *ngFor="let color of options" class="d-flex align-items-center p-2" (click)="onOptionSelect(color)">
      <span class="color-box mr-2" [style.backgroundColor]="color"></span> {{ color }}
    </button>
  </ng-container>
</hn-dropdown-menu>
