export enum RecordStatus {
    Final = 'Final',
    Approved = 'Approved',
    Review = 'Review',
    Draft = 'Draft',
    Uploaded = 'Uploaded',
    Hold = 'Hold',
    MailIn = 'Mail In',
    MailOut = 'Mail Out',
    NewClientFiles = 'New Client Files',
    ReadyForUpload = 'Ready for Upload',
}

