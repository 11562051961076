import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpmCallbackComponent } from './xpm';
import { WfmCallbackComponent } from './wfm';
import { SharedModule } from '../../../shared';
import { ModalsModule } from '../../../modals';

const connectionsCallbackRoutes: Routes = [
    {
        path: 'callback',
        children: [
            {
                path: 'xpm',
                component: XpmCallbackComponent,
            },
            {
                path: 'wfm',
                component: WfmCallbackComponent,
            }
        ]
    }

];

const DECLARATIONS = [
    XpmCallbackComponent,
    WfmCallbackComponent
];

const ENTRY_COMPONENTS = [
];

@NgModule({
    entryComponents: [
        ENTRY_COMPONENTS
    ],
    imports: [
        SharedModule,
        RouterModule.forChild(connectionsCallbackRoutes),
        ModalsModule
    ],
    declarations: [DECLARATIONS],
    exports: [RouterModule]
})
export class ConnectionsCallbackModule { }
