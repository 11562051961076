import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'message-modal',
    templateUrl: './message-modal.component.html',
    styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent implements OnInit {
    @Input() public title = '';
    @Input() public body = '';

    constructor(public activeModal: NgbActiveModal) { }

    public ngOnInit() {
    }

    public ok() {
        this.activeModal.close(true);
    }

    public cancel() {
        this.activeModal.close(false);
    }
}
