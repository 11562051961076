import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ITopSWPUser, IWorkpapersInserted } from '.';

@Injectable()
export class WorkpapersDatasource {
    private _dataUrl: string = 'reporting-workpapers';

    constructor(private _http: HttpClient) { }

    public getTopUsers(daysOffset: number): Observable<ITopSWPUser[]> {
        return this._http.get<ITopSWPUser[]>(`${this._dataUrl}/top-users?daysOffset=${daysOffset}`);
    }

    public getWorkpapersInserted(daysOffset: number): Observable<IWorkpapersInserted[]> {
        return this._http.get<IWorkpapersInserted[]>(`${this._dataUrl}/workpapers-inserted?daysOffset=${daysOffset}`);
    }
}
