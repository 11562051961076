import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { WopiDatasource } from '../../datasources/wopi/wopi.datasource';
import { UserService } from '../../services';

@Component({
    selector: 'hn-wopi-host',
    templateUrl: './wopi-host.component.html',
    styleUrls: ['./wopi-host.component.scss'],
    providers: [WopiDatasource]
})

export class WopiHostComponent implements OnInit, AfterViewInit {
    @Input()
    public action: string;

    @Input('file-id')
    public fileId: string;

    @Output('load')
    private _load = new EventEmitter();

    @Output('error')
    private _error = new EventEmitter<any>();

    @ViewChild('officeForm')
    private _officeFormElement: ElementRef;

    @ViewChild('frameHolder')
    private _frameHolderElement: ElementRef;

    public accessToken: string;
    public accessTokenTTL: number;
    public actionUrl: string;

    constructor(private _userService: UserService, private _wopiDatasource: WopiDatasource,
        private _renderer: Renderer2, private _cdkRef: ChangeDetectorRef) {
    }

    public ngOnInit() {
        this.accessToken = this._userService.accessToken;

        const tokenExpiryDate = new Date(1000 * this._userService.accessTokenLifeTime);
        this.accessTokenTTL = Date.UTC(tokenExpiryDate.getFullYear(), tokenExpiryDate.getMonth(), tokenExpiryDate.getDate(),
            tokenExpiryDate.getHours(), tokenExpiryDate.getMinutes(), tokenExpiryDate.getSeconds(), tokenExpiryDate.getMilliseconds());
    }

    public ngAfterViewInit() {
        this._wopiDatasource.getActionUrl(this.action, this.fileId).subscribe(url => {
            this.actionUrl = url;
            this._cdkRef.markForCheck();

            setTimeout(() => {
                const officeFrame = this._renderer.createElement('iframe');

                officeFrame.title = 'Office Frame';
                officeFrame.id = 'office_frame';
                officeFrame.name = 'office_frame';
                officeFrame.setAttribute('allowfullscreen', 'true');
                officeFrame.setAttribute('sandbox', 'allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox');

                this._renderer.appendChild(this._frameHolderElement.nativeElement, officeFrame);
                this._renderer.listen(officeFrame, 'load', () => {
                    this._load.emit();
                });

                this._officeFormElement.nativeElement.submit();
            });
        }, error => {
          this._error.emit(error);
        });
    }
}
