import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IServiceSetting } from '.';

@Injectable()
export class ServiceSettingsDatasource {
    private _dataUrl: string = 'service-settings';

    constructor(private _http: HttpClient) { }

    public post(serviceSettings: IServiceSetting[]): Observable<any> {
        return this._http.post<any>(`${this._dataUrl}`, serviceSettings);
    }
}
