import { IIconPack } from '../../controls/icon/icon-pack.interface';

export const ServicesIconPack: IIconPack = {
    name: 'services',
    icons: [
        'badugly',
        'hownow',
        'reportance',
        'smart'
    ]
};

