<div class="h-100 d-flex flex-nowrap flex-column justify-content-between">
    <div class="p-2 pt-3 no-select">
        <button type="button" mat-button (click)="collapsed.emit()" class="d-block d-xl-none">
            <hn-icon pack="shared" icon="close" size="1" color="secondary">
            </hn-icon>
        </button>
        <div class="text-center d-none d-xl-block">
            <img *ngIf="fullSize;else smallLogo" class="logo" src="assets/images/logo.svg">
            <ng-template #smallLogo>
                <img class="small-logo" src="assets/images/small-logo.svg">
            </ng-template>
        </div>
        <div class="mt-3">
            <div *ngFor="let item of menuItems" [ngClass]="fullSize ? 'px-2 py-1' : 'text-center my-2'">
                <button type="button" mat-button [routerLink]="item.path" [disabled]="item.disabled"
                    [color]="currentSectionUrl === item.path ? 'primary' : 'secondary'"
                    [ngClass]="fullSize ? 'text-left w-100' : 'text-center'" [matTooltip]="fullSize ? '' : item.label">
                    <hn-icon pack="shared" [icon]="item.icon" size="1.25"
                        [color]="currentSectionUrl === item.path ? 'primary' : 'secondary'">
                    </hn-icon>
                    <span class="ml-2" *ngIf="fullSize" [class.color-primary]="currentSectionUrl === item.path">
                        {{item.label}}
                    </span>
                </button>
            </div>
        </div>
    </div>
    <div class="w-100 p-3 text-center d-none d-xl-block overflow-hidden">
        <hr>
        <button type="button" mat-button (click)="collapsed.emit()" [matTooltip]="fullSize ? '' : 'Expand menu'"
            [ngClass]="fullSize ? 'text-left w-100' : 'text-center'">
            <hn-icon pack="shared" icon="collapse" size="1.25" color="secondary" [rotate]="fullSize ? '180' : '0'">
            </hn-icon>
            <span class="ml-2" *ngIf="fullSize">
                Collapse Menu
            </span>
        </button>
    </div>
</div>
