import { RouterModule } from '@angular/router';

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GoogleChartsModule } from 'angular-google-charts';
import {
    IndicatorComponent,
    CheckboxComponent,
    CheckboxGroupComponent,
    OptionSelectComponent,
    ChartComponent,
    StatusComponent,
    TableViewModule,
    CategorySearchComponent,
    DropdownMenuModule,
    TableHeaderFilterComponent,
    DateModule,
    HeaderComponent,
    SlideOutViewModule,
    SidebarMenuComponent,
    ProductsNavigationComponent,
    DropdownColorMenuComponent
} from '.';
import { IconModule } from './icon';

const IMPORTS_EXPORTS = [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    GoogleChartsModule,
    SlideOutViewModule,
    TableViewModule,
    DateModule,
    DropdownMenuModule,
];

const DECLARATIONS = [
    OptionSelectComponent,
    ChartComponent,
    StatusComponent,
    CategorySearchComponent,
    CheckboxComponent,
    CheckboxGroupComponent,
    IndicatorComponent,
    TableHeaderFilterComponent,
    HeaderComponent,
    SidebarMenuComponent,
    ProductsNavigationComponent,
    DropdownColorMenuComponent
];

@NgModule({
    declarations: [DECLARATIONS],
    imports: [
        IMPORTS_EXPORTS,
        IconModule
    ],
    providers: [
    ],
    exports: [
        IMPORTS_EXPORTS,
        DECLARATIONS
    ]
})
export class CoreControlsModule { }
