import { Component, Input, ViewChild, AfterViewInit, ElementRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { GoogleChartComponent, ChartType } from 'angular-google-charts';
import { BaseChartExtension } from './extensions/base.ext';
import ResizeObserver from 'resize-observer-polyfill';
import { getBarChartClickedItemIndex } from './functions/bar';
import { ScreenResolutionService } from '../../services/screen-resolution/screen-resolution.service';

@Component({
    selector: 'hn-chart',
    templateUrl: './chart.component.html'
})

export class ChartComponent implements AfterViewInit, OnDestroy {
    @ViewChild('chartContainer')
    public agNote: ElementRef;

    @ViewChild('chart')
    private _chart: GoogleChartComponent;

    @Input('type')
    public type: ChartType;

    @Input('data')
    public set data(value: any) {
        this._chartData = value;
        if (!this._chart) {
            return;
        }

        this._chart.ready.subscribe(() => {
            this.updateExtensions();
        });

    }

    public get data(): any {
        return this._chartData;
    }

    @Input('options')
    public options: any;

    @Input('columns')
    public columns: any;

    @Input('height')
    public height: any;

    @Input('item-grouped-amount')
    public itemGroupedAmount: number = 1;

    @Output('item-clicked')
    public itemClicked = new EventEmitter<number>();

    private _chartData: any;
    private _registeredExtensions: BaseChartExtension[] = [];
    private _containerResizeObserver: ResizeObserver;
    private get _chartElement() {
        return this._chart['element'].nativeElement;
    }

    public get isMobile(): boolean {
        return this._screenResolutionService.isMobile();
    }

    public get hasExtensions(): boolean {
        return this._registeredExtensions.length > 0;
    }

    constructor(private _screenResolutionService: ScreenResolutionService) {
    }

    public ngAfterViewInit(): void {
        this._chart.wrapperReady$.subscribe(() => {
            if (!this._containerResizeObserver) {
                this._containerResizeObserver = new ResizeObserver(() => {
                    this._chart.chartWrapper.draw();
                    this.updateExtensions();
                });
            }

            this._containerResizeObserver.observe(this.agNote.nativeElement);
        });
    }

    public ngOnDestroy() {
        this._containerResizeObserver.unobserve(this.agNote.nativeElement);
    }

    public registerExtensions(extensions: BaseChartExtension[]): void {
        this._registeredExtensions = this._registeredExtensions.concat(extensions);
    }

    public removeExtension(extensionType: any) {
        const index = this._registeredExtensions.findIndex(c => c instanceof extensionType);

        if (index > -1) {
            this._registeredExtensions.splice(index, 1);
        }
    }

    public updateExtensions(): void {
        const allowedDevices = ['all'];
        allowedDevices.push(this.isMobile ? 'mobile' : 'pc');

        this._registeredExtensions.filter(ext => allowedDevices.includes(ext.appliedDevices)).forEach(ext => {
            ext.applyChanges(this._chartElement);
        });
    }

    public onChartClicked(event) {
        let index = -1;
        if (this.type === ChartType.BarChart) {
            index = getBarChartClickedItemIndex(event, this._chartElement, this.data.length, this.itemGroupedAmount);
        }

        if (index === -1) {
            throw new Error('Not implemented!');
        }

        this.itemClicked.emit(index);
    }
}
