import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutes } from './app.routes';
import { RouterModule } from '@angular/router';
import { SharedModule } from './shared';
import { HttpClientModule } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';
import { CallbackComponent } from './pages/callback';
import { ConnectionsCallbackModule } from './pages/callback/connections';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OfficeComponent } from './pages/office/office.component';

@NgModule({
  declarations: [
    AppComponent,
    CallbackComponent,
    OfficeComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes),
    SharedModule,
    OAuthModule.forRoot(),
    ConnectionsCallbackModule,
    NgbModule
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
