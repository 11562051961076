import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'hn-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
})

export class HeaderComponent {
    @Input('name')
    public name: string = '';

    @Output('sidebar-opened')
    public sidebarOpened = new EventEmitter();

    @Output('log-out')
    public logOut = new EventEmitter();

    public logoff(): void {
        this.logOut.emit();
    }
}
