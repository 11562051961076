import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class WopiDatasource {
    private _dataUrl: string = 'wopi';

    constructor(private _http: HttpClient) { }

    public getActionUrl(action: string, fileId: string) {
        return this._http.get(`${this._dataUrl}/files/${fileId}/action/${action}/url`, { responseType: 'text' });
    }
}
