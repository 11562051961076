<div class="d-none d-xl-block py-1">
    <div class="d-flex flex-row">
        <div *ngFor="let option of options" class="px-2 pointer no-select"
            [ngClass]="value === option ? 'color-primary': 'color-secondary'" (click)="onOptionSelect(option)">
            <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: option }">
            </ng-container>
        </div>
    </div>
</div>

<hn-dropdown-menu [full-size]="false">
    <ng-container header>
        <div class="d-xl-none pointer m-auto pb-1">
            <hn-icon pack="shared" icon="settings" size="1" color="secondary">
            </hn-icon>
        </div>
    </ng-container>

    <ng-container menu>
        <button mat-menu-item *ngFor="let option of options"
            [ngClass]="value === option ? 'color-primary': 'color-secondary'" (click)="onOptionSelect(option)">
            <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: option }">
            </ng-container>
        </button>
    </ng-container>
</hn-dropdown-menu>