import { Component, Input } from '@angular/core';

@Component({
    selector: 'hn-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss']
})

export class StatusComponent {
    @Input('color')
    public color: string = 'primary';
}
