import { ChangeDetectionStrategy, Component, forwardRef, HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DefaultValueAccessor } from '../forms/value-accessors/default-value-accessor';

@Component({
    selector: 'hn-dropdown-color-menu',
    templateUrl: './dropdown-color-menu.component.html',
    styleUrls: ['./dropdown-color-menu.component.scss'],
    providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownColorMenuComponent),
      multi: true
    }]
})

export class DropdownColorMenuComponent extends DefaultValueAccessor<any> implements ControlValueAccessor {
  public options: any[] = [
    'Red',
    'Orange',
    'Yellow',
    'Green',
    'Blue',
    'Purple',
    'Brown'
  ];
  public disabled: boolean = false;

  public onOptionSelect(option: any): void {
    this.value = option;
  }

  public setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  public get valueText(): string {
    if (!this.value || typeof(this.value) !== 'string') {
      return null;
    }

    const strValue = this.value.toString();
    if (strValue.startsWith('#')) {
      return 'Custom...';
    } else {
      return strValue;
    }
  }
}
