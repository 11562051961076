import { IIconPack } from '../../controls/icon/icon-pack.interface';

export const SharedIconPack: IIconPack = {
    name: 'shared',
    icons: [
        'arrow',
        'burger',
        'checklist',
        'close',
        'collapse',
        'contact',
        'dictionary',
        'home',
        'pie-chart',
        'portal',
        'record',
        'settings',
        'chevron',
        'search',
        'user-group',
        'download',
        'cancel',
        'eye',
        'sort-ascending',
        'sort-descending',
        'type',
        'down',
        'edit',
        'dots',
        'settings-gear',
        'option-connect',
        'option-employees',
        'option-organization',
        'option-server-addin',
        'option-firm-settings',
        'option-updates',
        'option-knowledge',
        'option-contacts',
        'option-records',
        'option-workflow'
    ]
};

