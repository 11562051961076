import { Injectable, Inject, Type, StaticProvider } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { finalize } from 'rxjs/operators';
import { SlideOutViewComponent } from './slide-out-view';
import { SLIDE_OUT_OPTIONS } from './slide-out-options.provider';
import { SlideOutViewContainerComponent } from './slide-out-view-container';
import { Compiler } from '../../services/compiler/compiler.service';

@Injectable()
export class SlideOutView {

    constructor(private _compiler: Compiler, @Inject(DOCUMENT) private _document: Document) {
    }

    public open<T extends SlideOutViewComponent>(
        concreteType: Type<T>,
        options: any = {},
        providers: StaticProvider[] = []
    ) {
        providers = [
            { provide: SLIDE_OUT_OPTIONS, useValue: options }, ...(providers || [])
        ];
        const slideOutView = this._compiler.createComponentRef(SlideOutViewContainerComponent, concreteType, providers);
        this._document.body.appendChild(slideOutView.location.nativeElement);

        const observable = slideOutView.instance.open();
        const subscription = observable.pipe(finalize(() => {
            this._compiler.destroyComponentRef(slideOutView);
            subscription.unsubscribe();
        })).subscribe();

        return observable;
    }
}
