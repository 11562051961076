import { Routes } from '@angular/router';
import { AuthorizedGuard } from './shared/guards';
import { CallbackComponent } from './pages/callback';
import { OfficeComponent } from './pages/office/office.component';

export const AppRoutes: Routes =
  [
    {
      path: 'auth-callback',
      component: CallbackComponent
    },
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'user'
    },
    {
      path: 'user',
      canActivate: [AuthorizedGuard],
      loadChildren: () => import('./pages/user/user.module')
        .then(mod => mod.UserModule)
    },
    {
      path: 'office/:recordId/:action',
      canActivate: [AuthorizedGuard],
      component: OfficeComponent
    }
  ];
