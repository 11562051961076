import { NgModule } from '@angular/core';
import { DropdownMenuComponent } from './dropdown-menu.component';
import { MatMenuModule } from '@angular/material/menu';

const IMPORTS = [
    MatMenuModule
];

const EXPORTS = [
    DropdownMenuComponent,
];

const DECLARATIONS = [
    EXPORTS,
];

const PROVIDERS = [
];

@NgModule({
    imports: IMPORTS,
    declarations: [DECLARATIONS],
    exports: [EXPORTS, IMPORTS],
    providers: PROVIDERS
})

export class DropdownMenuModule { }
