import { Inject, Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { LocalStorage } from '../../../core/services/storage';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import decodeJwt from 'jwt-decode';
import { IUserInfo } from './models/user-info.interface';
import { UserType } from './models/user-type.enum';
import { ROLE_ACCESS_LEVELS } from './models/role-access-level.const';
import { CONFIG, Configuration } from '../../../../environments/environment';

export const RETURN_URL = 'return-url';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _userInfo: IUserInfo;
  private tokenExpiresIn: number;

  private get userInfo(): IUserInfo {
    if (!this._userInfo) {

      const token = this._oauthService.getAccessToken();
      this.setUserInfo(token);
    }

    return this._userInfo;
  }

  constructor(
    @Inject(CONFIG) _config: Configuration,
    private _localStorage: LocalStorage,
    private _router: Router,
    private _oauthService: OAuthService) {
    this._oauthService.configure(_config.clientSettings);
    this._oauthService.loadDiscoveryDocumentAndTryLogin();
  }

  public get email(): string {
    return this.userInfo.email;
  }

  public get name(): string {
    return this.userInfo.name;
  }

  public get roles(): UserType[] {
    return this.userInfo.roles;
  }

  public get accessToken(): string {
    return this._oauthService.getAccessToken();
  }

  public get accessTokenLifeTime(): number {
    if (!this.tokenExpiresIn) {
      const token = this._oauthService.getAccessToken();
      this.setUserInfo(token);
    }
    return this.tokenExpiresIn;
  }

  public get roleMaxRoleAccessLevel(): number {
    let maxRoleAccessLevel = -1;

    this.roles.forEach(userType => {
      const roleAccessLevel = ROLE_ACCESS_LEVELS.find(ra => ra.userType === userType).level;

      if (roleAccessLevel > maxRoleAccessLevel) {
        maxRoleAccessLevel = roleAccessLevel;
      }
    });

    return maxRoleAccessLevel;
  }

  public get authorizationHeader(): string {
    return `Bearer ${this._oauthService.getAccessToken()}`;
  }

  public isLoggedIn(): boolean {
    return this._oauthService.hasValidAccessToken();
  }

  public startAuthentication(callbackLoginUrl: string): void {
    this._localStorage.set(RETURN_URL, callbackLoginUrl);
    this._oauthService.initCodeFlow();
  }

  public refresh() {
    this._oauthService.refreshToken();
  }

  public logOut(): void {
    this._localStorage.clear();
    this._oauthService.revokeTokenAndLogout();
  }

  public registerToken(): void {
    this._oauthService.events
      .pipe(filter(e => e.type === 'token_received'))
      .subscribe(() => {
        const returnUrl = this._localStorage.get(RETURN_URL);

        this._router.navigate([returnUrl]);
      });
  }

  private setUserInfo(token: string) {
    const parsedToken = this.decodeAccessToken(token);

    this._userInfo = {
      name: parsedToken['name'],
      email: parsedToken['email'],
      emailVerified: parsedToken['email_verified'],
      firmId: parsedToken['hnx_firmid'],
      userId: parsedToken['hnx_userid'],
      roles: parsedToken['hnx_roles']
    };

    this.tokenExpiresIn = parsedToken.exp;
  }

  private decodeAccessToken(token: string) {
    return decodeJwt(token);
  }
}
