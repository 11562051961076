import { Component, HostListener, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'hn-dropdown-menu',
  templateUrl: './dropdown-menu.component.html'
})

export class DropdownMenuComponent {
  @ViewChild('menu')
  private _menu: MatMenu;

  @ViewChild('menuTrigger')
  private _menuTrigger: MatMenuTrigger;

  @ViewChild('container')
  private _container: ElementRef;

  @Input('menu-class')
  public menuClass: string;

  @Input('autoclose')
  public autoclose: boolean = true;

  @Input('full-size')
  public fullSize: boolean = true;

  @Output('open')
  public menuOpen = new EventEmitter<any>();

  @Output('close')
  public menuClose = new EventEmitter<any>();

  public menuWidth: string = '100%';

  private _isOpened: boolean = false;
  public get isOpened() {
    return this._isOpened;
  }

  @HostListener('document:keydown.escape', ['$event'])
  public onKeydownHandler() {
    this._menu.closed.emit();
  }

  public onMenuOpen() {
    this.menuWidth = '100%';

    if (this.fullSize) {
      const computedComponentStyles = getComputedStyle(this._container.nativeElement);
      this.menuWidth = computedComponentStyles.width;
    }

    this.menuOpen.emit();
    this._isOpened = true;
  }

  public onMenuClose() {
    this.menuClose.emit();
    this._isOpened = false;
  }

  public onClick($event) {
    if (!this.autoclose) {
      $event.stopPropagation();
    }
  }

  public open() {
    this._menuTrigger.openMenu();
  }

  public close() {
    this._menu.closed.emit();
  }
}
