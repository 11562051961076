import { Pipe, PipeTransform } from '@angular/core';
import { StringHelpers } from '../utilities/string-helpers';

@Pipe({
  name: 'enumToArray'
})
export class EnumToArrayPipe implements PipeTransform {

  public transform(value: any, args?: any): any {
    // Sort enum keys to preserve negative key order
    const keys = Object.keys(value).sort((a, b) => {
      const a1 = parseInt(a, 10);
      const b1 = parseInt(b, 10);

      if (isNaN(a1) && isNaN(b1)) {
        return 1;
      }

      if (!isNaN(a1) && isNaN(b1)) {
        return 1;
      }

      if (isNaN(a1) && !isNaN(b1)) {
        return -1;
      }

      return a1 - b1;
    });

    const values = keys.length / 2;
    const array = [];
    for (let i = values; i < keys.length; i++) {
      const converted = parseInt(keys[i], 10);
      if (!isNaN(converted)) {
        array.push({ value: converted, name: StringHelpers.convertToSentence(keys[i - values]) });
      } else {
        array.push({ value: keys[i], name: StringHelpers.convertToSentence(keys[i - values]) });
      }
    }
    return array;
  }
}
