import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'hn-sidebar-menu',
    templateUrl: './sidebar-menu.component.html',
    styleUrls: ['./sidebar-menu.component.scss']
})

export class SidebarMenuComponent {
    @Input('full-size')
    public fullSize: true;

    @Input('menu-items')
    public menuItems: any[];

    @Output('collapsed')
    public collapsed = new EventEmitter();

    constructor(private _router: Router) {
    }

    public get currentSectionUrl(): string {
        let currentUrl = '';

        this._router.url.split('/').forEach((line, index, array) => {
            if (index > 1) {
                currentUrl = `${currentUrl}${line}`;

                if (array.length - 1 !== index) {
                    currentUrl = `${currentUrl}/`;
                }
            }
        });

        return currentUrl.toLocaleLowerCase();
    }
}
