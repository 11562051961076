export const ITEMS_AMOUNT_PER_REQUEST = 100;

export const WOPI_SUPPORTED_FORMATS = [
    'XLS', 'XLSX', 'DOC', 'DOCX',
    'CSV', 'ONE', 'ONETOC2', 'ODP',
    'POT', 'POTM', 'POTX', 'PPS',
    'PPSM', 'PPSX', 'PPT', 'PPTM',
    'PPTX', 'VDW', 'VSD', 'VSDM',
    'VSDX', 'RTF', 'PDF', 'WOPITEST', 'WOPITESTX'
];
export const WOPI_EDIT_SUPPORTED_FORMATS = [
    'ODS', 'XLSB', 'XLSM', 'XLSX', 'ONE',
    'ONETOC2', 'ODP', 'PPSX', 'PPTX', 'VSDX',
    'DOCM', 'DOCX', 'ODT'
];
export const DEFAULT_GREETINGS = [
  'Dear',
  'Hello',
  'Good Morning',
  'Good Afternoon',
  'Hi'
];
export const DEFAULT_SIGN_OFF_PHRASES = [
  'Yours faithfully',
  'Yours sincerely',
  'Yours truly',
  'Regards',
  'Sincerely',
  'Best regards',
  'Warm regards',
  'Kind regards'];

export const DEFAULT_TABS = [
  'Organisation',
  'Knowledge',
  'Records',
  'Contacts',
  'Jobs',
  'Tasks'];
