import { BaseChartExtension, AppliedDevices } from '../base.ext';

export class BarLabelsYOffsetChartExtension extends BaseChartExtension {
    private _labelsDefaultY: number[] = [];
    private _svgWidth: number;

    constructor(private _yOffset: number, appliedDevices: AppliedDevices) {
        super();
        this.appliedDevices = appliedDevices;
    }

    public applyChanges(chartElement: any): void {
        const svg = super.getChartSvg(chartElement);
        if (!svg) {
            return;
        }

        const svgWidth = svg.getAttribute('width');
        if (!svgWidth) {
            this._svgWidth = svgWidth;
        }

        const g = svg.getElementsByTagName('g')[0];
        const labelsG = g.children[g.children.length - 2];

        const texts = labelsG.getElementsByTagName('text');

        if (this._svgWidth !== svgWidth) {
            this._svgWidth = svgWidth;
            this._labelsDefaultY = [];
        }

        for (let i = 0; i < texts.length; i++) {
            if (!this._labelsDefaultY[i]) {
                this._labelsDefaultY[i] = +texts[i].getAttribute('y');
            }

            texts[i].setAttribute('y', this._labelsDefaultY[i] + this._yOffset);
        }
    }
}
