<hn-dropdown-menu
  (open)="onMenuChange(true)"
  (close)="onMenuChange(false)"
  [full-size]="false"
  menu-class="products-navigation-menu"
  *ngIf="false">
    <ng-container header>
        <div class="d-flex h-100">
            <button mat-button class="px-4" [disableRipple]="true">
                <hn-icon pack="shared" icon="dots" size="1" [color]="isMenuOpened ? 'primary' : 'secondary'">
                </hn-icon>
            </button>
        </div>
    </ng-container>

    <ng-container menu>
        <div class="px-3">
            <div *ngFor="let productGroup of productGroups">
                <h5 class="my-3 color-secondary-700 uppercase no-select">
                    {{productGroup.name}}
                </h5>

                <div *ngFor="let product of productGroup.products"
                    class="mt-2 d-flex align-items-center bold pointer no-select ml-{{product.level}}"
                    (click)="navigateTo(product.url)">
                    <div class="rounded shadow-light-secondary-400 p-1 mr-3">
                        <hn-icon pack="services" [icon]="product.icon" size="1.6">
                        </hn-icon>
                    </div>

                    {{product.name}}
                </div>
            </div>
        </div>
    </ng-container>
</hn-dropdown-menu>
