export const getBarChartClickedItemIndex = (event, chartElement, dataLength, itemGroupedAmount) => {
    const boundingRect = chartElement.getBoundingClientRect();
    const yDistancePerRow = (boundingRect.height / dataLength) * itemGroupedAmount;

    const yClickedChartDistance = event.y - boundingRect.y;
    const clickedElementIndex = Math.trunc(yClickedChartDistance / yDistancePerRow);

    return clickedElementIndex;
};

